import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

const Refresh = () => {

  useEffect(() => {
    localStorage.setItem("finished", false);
    localStorage.removeItem('formDataQuestionnaire');
    localStorage.removeItem('formDataStyle');
  }, []);

  return (
    <div className='container-refresh'>
      <h1>
        Anda sudah bisa kembali mengisi kuisioner
      </h1>
      <Link className='link' to="/kuisioner">Kembali ke Halaman Kuisioner</Link>
      <Link className='link' to="/style">Kembali ke Halaman Style</Link>
    </div>
  );
}

export default Refresh;
