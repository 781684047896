/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';
import emailjs from 'emailjs-com';

import {
  InputForm,
  Button,
  RadioButton,
  InputGroup,
  ImageGallery,
  ImageItem,
  Textarea,
  Popup
} from 'components';

import { scroller } from 'react-scroll';

import imageUrls from 'assets/images';
import './styles.scss';

import history from '../history';

const Homepage = () => {

  const [hideLoader, sethideLoader] = useState(false);
  const [isProceed, setisProceed] = useState(false);
  const [isFinished, setisFinished] = useState(false);
  const [isShowFloating, setisShowFloating] = useState(false);
  const [titleSectionName, settitleSectionName] = useState();
  const [hasFilled, sethasFilled] = useState(false);
  const [totalChild, settotalChild] = useState(1);

  const [disabledBtnNext, setdisabledBtnNext] = useState(false);
  const [disabledProceed, setdisabledProceed] = useState(true);

  // popup image gallery
  const [showPopup, setshowPopup] = useState(false);
  const [imageDetailStyle, setimageDetailStyle] = useState([]);

  const isHideLoader = hideLoader? 'hide': '';
  const isHideSectionOpener = isProceed? 'hide': '';
  const isShowSectionFinish = isFinished? 'show': '';
  const showFloating = isShowFloating? 'show': '';

  const [activeIndex, setActiveIndex] = useState(0);

  const totalSlides = 19;

  const initialFormData = {
    namaLengkap: '',
    ambience: [],
    ambienceNote: '',
    style: '',
    materialBoard: [],
    shape: '',
    // type: '',
    type: [],
    note: ''
  }

  const storedFormDataSaved = localStorage.getItem('formDataStyle');

  const [formData, setFormData] = useState(storedFormDataSaved ? JSON.parse(storedFormDataSaved) : initialFormData);

  const dataSection = [
    "Ambience",
    "Style",
    "Material Board",
    "Shape",
    "Type",
  ];

  const handleClickProceed = () => {
    setisProceed(true);
    document.body.style.overflow = 'auto'; // or 'scroll'

    setActiveIndex(0);
    settitleSectionName(`${dataSection[0]}`);
    setdisabledBtnNext(true);

    setTimeout(() => {
      setisShowFloating(true);
      updateTotalInputsPerSlide();
    }, 1000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Split the name into an array to access the nested structure
    const nameParts = name.split('.');

    if (name === 'namaLengkap') {
      setdisabledProceed(value === "");
    }


    if (nameParts[1] === undefined) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // Use the nameParts to update the formData
      setFormData((prevData) => ({
        ...prevData,
        [nameParts[0]]: {
          ...prevData[nameParts[0]],
          [nameParts[1]]: value,
        },
      }));
    }
  };

  const handleBlur = (e) => {
    console.log(
      // '@@validateInput', validateInputs(activeIndex)
    );
  };

  const updateTotalInputsPerSlide = () => {
    const sliderWrapper = document.querySelector('.slider-wrapper.active');
    if (sliderWrapper) {
      const inputs = sliderWrapper.getElementsByTagName('input');
      const uniqueInputNames = new Set();

      for (const input of inputs) {
        uniqueInputNames.add(input.name);
      }
    }
  };

  const handleClickPrev = () => {
    scroller.scrollTo('formQuestionaire', {
      duration: 500, // Duration of the scroll animation in milliseconds
      smooth: 'easeInOutQuart', // Easing function for smooth scrolling
    });

    setActiveIndex((prevIndex) =>  Math.max(prevIndex - 1, 0));
    settitleSectionName(`${dataSection[activeIndex - 1]}`);
    setTimeout(() => {
      updateTotalInputsPerSlide();
    }, 500);

    setdisabledBtnNext(false);
  };

  const handleClickSelanjutnya = () => {
    // Validate the inputs before moving to the next slide
    scroller.scrollTo('formQuestionaire', {
      duration: 500, // Duration of the scroll animation in milliseconds
      smooth: 'easeInOutQuart', // Easing function for smooth scrolling
    });

    if (activeIndex === 4) {
      setisShowFloating(false);

      sessionStorage.setItem('data', formData);

      scroller.scrollTo('formQuestionaire', {
        duration: 500, // Duration of the scroll animation in milliseconds
        smooth: 'easeInOutQuart', // Easing function for smooth scrolling
      });

      setTimeout(() => {
        setisFinished(true);
        localStorage.removeItem('formDataStyle');
        handleSendEmail();
        localStorage.setItem('finished', true);
        document.body.style.overflow = 'hidden';
      }, 600);

    } else {
      if (validateInputs(activeIndex)) {
        setActiveIndex((prevIndex) => Math.min(prevIndex + 1, totalSlides - 1));
        settitleSectionName(`${dataSection[activeIndex + 1]}`);
        setdisabledBtnNext(true);
      } else {
        alert('Data yang diperlukan belum diisi. Harap isi seluruh data untuk dapat melanjutkan');
        document.body.style.overflow = 'auto'; // or 'scroll'
      }
    }
  };

  const validateInputs = (index) => {
    // Get the slider-wrapper element corresponding to the active index
    const sliderWrapper = document.getElementsByClassName('slider-wrapper')[index];
    const sliderId = sliderWrapper.id;
    let isFilled = false;

    // isFilled = formData[sliderId] !== '';
    // isFilled = Object.values(formData[sliderId]).every(value => value !== '');
    isFilled = formData[sliderId].length;

    if (isFilled) {
      // All children keys of "personalInfo" are non-empty
      return true;
    } else {
      // Not all children keys of "personalInfo" are non-empty
      return false;
    }
  };

  const handleSendEmail = async () => {
    try {
      const htmlContent = `
        Hello, Manna Interior Team.
        Tuan/Nyonya ${formData.namaLengkap} baru saja mengisi form kuisioner Manna Interior. Berikut ini data lengkapnya :

        Ambience : ${formData.ambience}
        Style : ${formData.style}
        Material Board(s) : ${formData.materialBoard}
        Shape : ${formData.shape}
        Type : ${formData.type}
        Type Note : ${formData.note}

        Terima kasih!
      `;

      const templateParams = {
        from_name: `${formData.namaLengkap}`,
        to_name: 'Manna Interior Team',
        html: htmlContent
      };

      const serviceID = 'service_02xjltq';
      const templateID = 'template_v0zwq8q';
      const userID = '_niUb8iq8liP1ic3g'; // Use your User ID here

      const response = await emailjs.send(serviceID, templateID, templateParams, userID);

    } catch (error) {
      console.error('Email failed to send:', error);
    }
  };

  const renderAmbience = () => {
    return (
      <>
        <ImageGallery key="ig-1" setFormData={setFormData} formData={formData} style={formData.ambience} value="Ambience bright" name="ambience" desc={"Bright"} thumb={imageUrls.ambienceBright} images={imageUrls.ambienceBrightList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-2" setFormData={setFormData} formData={formData} style={formData.ambience} value="Ambience dark" name="ambience" desc={"Dark"} thumb={imageUrls.ambienceDark} images={imageUrls.ambienceDarkList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-3" setFormData={setFormData} formData={formData} style={formData.ambience} value="Ambience warm" name="ambience" desc={"Warm"} thumb={imageUrls.ambienceWarm} images={imageUrls.ambienceWarmList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
      </>
    );
  };
  const renderStyle = () => {
    return (
      <>
        <ImageGallery key="ig-4" setFormData={setFormData} formData={formData} style={formData.style} value="Bohemian" name="style" desc={"Bohemian"} thumb={imageUrls.bohemian} images={imageUrls.bohemianList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-5" setFormData={setFormData} formData={formData} style={formData.style} value="Dark interior" name="style" desc={"Dark Interior"} thumb={imageUrls.darkInterior} images={imageUrls.darkInteriorList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-6" setFormData={setFormData} formData={formData} style={formData.style} value="Farmhouse" name="style" desc={"Farmhouse"} thumb={imageUrls.farmhouse} images={imageUrls.farmhouseList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-7" setFormData={setFormData} formData={formData} style={formData.style} value="Modern-classic" name="style" desc={"Klasik Modern"} thumb={imageUrls.klasikModern} images={imageUrls.klasikModernList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-8" setFormData={setFormData} formData={formData} style={formData.style} value="Modern elegan" name="style" desc={"Modern Elegan"} thumb={imageUrls.modernElegan} images={imageUrls.modernEleganList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-9" setFormData={setFormData} formData={formData} style={formData.style} value="Modern industrial" name="style" desc={"Modern Industrial"} thumb={imageUrls.modernIndustrial} images={imageUrls.modernIndustrialList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-10" setFormData={setFormData} formData={formData} style={formData.style} value="Modern japandi" name="style" desc={"Modern Japandi"} thumb={imageUrls.modernJapandi} images={imageUrls.modernJapandiList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-11" setFormData={setFormData} formData={formData} style={formData.style} value="Modern contemporary" name="style" desc={"Modern Kontemporer"} thumb={imageUrls.modernKontemporer} images={imageUrls.modernKontemporerList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-12" setFormData={setFormData} formData={formData} style={formData.style} value="Modern luxury" name="style" desc={"Modern Luxury"} thumb={imageUrls.modernLuxury} images={imageUrls.modernLuxuryList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-13" setFormData={setFormData} formData={formData} style={formData.style} value="Modern nature" name="style" desc={"Modern Natural"} thumb={imageUrls.modernNature} images={imageUrls.modernNatureList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-14" setFormData={setFormData} formData={formData} style={formData.style} value="Modern oriental" name="style" desc={"Modern Oriental"} thumb={imageUrls.modernOriental} images={imageUrls.modernOrientalList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-15" setFormData={setFormData} formData={formData} style={formData.style} value="Wabi sabi" name= "style" desc={"Wabi Sabi"} thumb={imageUrls.wabiSabi} images={imageUrls.wabiSabiList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
      </>
    );
  };
  const renderMaterialBoard = () => {
    return imageUrls.materialBoardList.map((v, index) => (
      <ImageGallery key={`ig-16-${index}`} multipleMaterial setFormData={setFormData} formData={formData} style={formData.materialBoard} value={`Material No.${index + 1}`} name="materialBoard" thumb={v} />
    ));
  };
  const renderShape = () => {
    return (
      <>
        <ImageGallery key="ig-17" setFormData={setFormData} formData={formData} style={formData.shape} value="Curve" name="shape" desc={"Curve"} thumb={imageUrls.shapeCurve} images={imageUrls.shapeCurveList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-18" setFormData={setFormData} formData={formData} style={formData.shape} value="Square" name="shape" desc={"Square"} thumb={imageUrls.shapeSquare} images={imageUrls.shapeSquareList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-19" setFormData={setFormData} formData={formData} style={formData.shape} value="Triangle" name="shape" desc={"Triangle"} thumb={imageUrls.shapeTriangle} images={imageUrls.shapeTriangleList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
      </>
    );
  };
  const renderType = () => {
    return (
      <>
        <div>
          <ImageGallery key="ig-20-1" multipleType setFormData={setFormData} formData={formData} style={formData.type} value="Simple" name="type" desc={"Simple"} thumb={imageUrls.simple} images={imageUrls.simpleList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
          <ImageGallery key="ig-21-2" multipleType setFormData={setFormData} formData={formData} style={formData.type} value="Detail" name="type" desc={"Detail"} thumb={imageUrls.detail} images={imageUrls.detailList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        </div>
        <div style={{maxWidth: "700px", margin: "0 auto", textAlign: "left", marginTop: "32px"}}>
          <InputForm placeholder='tambahkan catatan pada ruangan tertentu (contoh: ruang tamu - simple, ruang tv - detail)' variant="text-top" type='text' labelError='' label='Catatan Tambahan (Opsional)' name='note' value={formData.note} onChange={handleChange} onBlur={handleBlur} />
        </div>
        {/* <div >
          <Textarea name='note' value={formData.note} label='ini lho' onChange={handleChange} />
        </div> */}
      </>
    );
  };

  // useEffect
  useEffect(() => {
    const isFinished = localStorage.getItem('finished');

    if (isFinished === 'true') {
      sethasFilled(isFinished);
    } else {
      sethasFilled(false);
    }

    setTimeout(() => {
      sethideLoader(true);
      // document.body.style.overflow = 'hidden';
    }, 3500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (validateInputs(activeIndex)) {
        setdisabledBtnNext(!validateInputs(activeIndex));
      } else {
        setdisabledBtnNext(true);
      }
    }, 200);

  }, [handleChange, handleClickSelanjutnya]);

  useEffect(() => {
    localStorage.setItem('formDataStyle', JSON.stringify(formData));
    const storedFormData = localStorage.getItem('formDataStyle');

    // Check if the data exists in localStorage
    if (storedFormData) {
      // Parse the JSON string to convert it back to a JavaScript object
      const formData = JSON.parse(storedFormData);

      // Access the desired value from the object
      const personalInfoName = formData.namaLengkap;

      console.log(`the value ${personalInfoName}`); // Output the value of personalInfo.name
    }
  }, [handleChange]);

  useEffect(() => {
    if (storedFormDataSaved) {
      setdisabledProceed(false);
    }

    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        const confirmLeave = window.confirm('Are you sure you want to leave this page?');
        if (!confirmLeave) {
          history.push(history.location.pathname);
          return false;
        }
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, []);


  return (
    <>
      {/* for every transition page  */}
      <section className={`loader ${isHideLoader}`}>
        <div className='container'>
          <img src={imageUrls.gifLoaderLogo} alt='Logo' />
        </div>
      </section>

      <section className={`opener ${isHideSectionOpener}`}>
        <div className='img-bg'></div>
        <div className='container'>
          <div className='row'>
            <div className={`col col-d-60 col-m-80 h-center vh-100 section-divider`}>
              <div className='v-center has-cover ta-l ta-m-c'>
                {hasFilled? (
                  <>
                    <h1 className='a-h1 mb-16 mb-32'>
                      <b>Anda sudah mengisi kuisioner ini.</b>
                    </h1>
                    <p className='a-p mb-40'>
                      Anda hanya dapat mengisi formulir ini secara satu kali saja. <br/>
                      Coba hubungi pemilik formulir ini jika menurut Anda hal ini adalah kesalahan.
                    </p>
                  </>
                ): (
                  <>
                    <InputForm className='txt-white' type='text' variant='text-top' label='Nama Lengkap Kustomer' name='namaLengkap' value={formData.namaLengkap} onChange={handleChange} onBlur={handleBlur} mandatory />
                    <br/>
                    <Button className='mb-m-8' label='Mulai Mengisi' variant='primary' disabled={disabledProceed} onClick={handleClickProceed} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`closer finished ${isShowSectionFinish}`}>
        <div className='img-bg'></div>
        <div className='container'>
          <div className='row'>
            <div className={`col col-d-60 col-m-80 h-center vh-100 section-divider`}>
              <div className='v-center has-cover'>
                <h1 className='a-h1 mb-16 mb-32'>
                  Terima Kasih atas respon Anda!
                </h1>
                <p className='a-p mb-40'>
                  Anda hanya dapat mengisi formulir ini secara satu kali saja. <br/>
                  Coba hubungi pemilik formulir ini jika menurut Anda hal ini adalah kesalahan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={`btn-wrapper dn db-m floating floating-top ${showFloating}`}>
        <div className='progress'></div>
        <div className='row'>
          <div className='col col-d-60 col-m-90 h-center ta-r'>
            <h3 className='title-section'>
              {titleSectionName}
            </h3>
          </div>
        </div>
      </div>

      <section className='questionnaire'>
        <div className="container">
          <div className='row'>
            {/* Slider Section  */}
            <div id="formQuestionaire" className='col col-d-full ta-c col-m-90 mvh-100 h-center'>
              <div className='slider pt-80 pb-120 pb-m-96'>
                {/* slider 1  */}
                <div id="ambience" className={`slider-wrapper ${activeIndex === 0? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Silahkan pilih <b>design ambience</b> yang ada dalam pilihan di bawah ini:
                    </h4>
                    {renderAmbience()}
                  </div>
                </div>
                {/* slider 2  */}
                <div id="style" className={`slider-wrapper ${activeIndex === 1? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Silahkan pilih <b>design style</b> yang ada dalam pilihan di bawah ini:
                    </h4>
                    {renderStyle()}
                  </div>
                </div>
                {/* slider 3  */}
                <div id="materialBoard" className={`slider-wrapper ${activeIndex === 2? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Pilih <b>material board</b> yang tersedia.
                      <br/>
                      <div className='dn db-m'>
                        Material dapat dipilih <b>lebih dari satu.</b>
                      </div>
                      <div className='db dn-m'>
                        Material dapat dipilih <b>lebih dari satu item</b>.
                      </div>
                    </h4>
                    {renderMaterialBoard()}
                  </div>
                </div>
                {/* slider 4  */}
                <div id="shape" className={`slider-wrapper ${activeIndex === 3 ? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Pilih <b>shape</b> yang diinginkan:
                    </h4>
                    {renderShape()}
                  </div>
                </div>
                {/* slider 5  */}
                <div id="type" className={`slider-wrapper ${activeIndex === 4 ? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Pilih <b>design type</b> yang diinginkan:
                    </h4>
                    {renderType()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showPopup && (
        <Popup>
          <ImageItem setshowPopup={setshowPopup} setisShowFloating={setisShowFloating} imageDetailStyle={imageDetailStyle} setimageDetailStyle={setimageDetailStyle} />
        </Popup>
      )}

      <div className={`btn-wrapper floating ${showFloating}`}>
        <div className='progress'></div>
        <div className='row'>
          <div className='col col-d-60 col-m-90 h-center ta-r'>
            <h3 className='title-section dn-m'>
              {`
                ${titleSectionName}
              `}
            </h3>
            {activeIndex > 0 && (
              <Button className='kembali mr-8 mr-m-0 pl-m-0' label='Kembali' variant='secondary' onClick={handleClickPrev} />
            )}
            <Button disabled={disabledBtnNext} className='' label='Selanjutnya' variant='primary' onClick={handleClickSelanjutnya} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
