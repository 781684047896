/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';
import emailjs from 'emailjs-com';

import {
  InputForm,
  Button,
  RadioButton,
  InputGroup,
  ImageItem,
  Popup
} from 'components';

import { scroller } from 'react-scroll';

import imageUrls from 'assets/images';
import './styles.scss';
import history from '../history';

const Homepage = () => {

  const [hideLoader, sethideLoader] = useState(false);
  const [isProceed, setisProceed] = useState(false);
  const [isFinished, setisFinished] = useState(false);
  const [isShowFloating, setisShowFloating] = useState(false);
  const [titleSectionName, settitleSectionName] = useState();
  const [hasFilled, sethasFilled] = useState(false);
  const [totalChild, settotalChild] = useState(1);
  const [hasParentRoom, sethasParentRoom] = useState(false);
  const [hasLivingRoom, sethasLivingRoom] = useState(false);

  const [disabledBtnNext, setdisabledBtnNext] = useState(false);

  // popup image gallery
  const [showPopup, setshowPopup] = useState(false);
  const [imageDetailStyle, setimageDetailStyle] = useState([]);

  const isHideLoader = hideLoader? 'hide': '';
  const isHideSectionOpener = isProceed? 'hide': '';
  const isShowSectionFinish = isFinished? 'show': '';
  const showFloating = isShowFloating? 'show': '';

  const [activeIndex, setActiveIndex] = useState(0);

  const [totalSlides, settotalSlides] = useState(11);

  const initialFormData = {
    personalInfo: {
      name: '',
      email: '',
      phone: '',
      jumlahAnggota: '',
      jumlahAnak: '',
      memilikiRuangTamu: '',
      tinggalDenganOrangTua: '',
      memilikiRencana: '',
      biodata: ''
    },
    kamarUtama: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
      quest5: '',
      quest6: '',
      quest7: '',
    },
    kamarAnak1: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
      quest5: '',
    },
    kamarAnak2: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
      quest5: '',
    },
    kamarAnak3: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
      quest5: '',
    },
    kamarTamu: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
    },
    kamarOrangTua: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
    },
    ruangTamu: {
      quest1: '',
      quest2: '',
    },
    foyer: {
      quest1: '',
      quest2: '',
      quest3: '',
    },
    ruangHobi: {
      quest1: '',
      quest2: '',
    },
    ruangMakan: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
      quest5: '',
      quest6: '',
      quest7: '',
      quest8: '',
      quest9: '',
      quest10: '',
      quest11: '',
    },
    ruangKerja: {
      quest1: '',
    },
    ruangBelajar: {
      quest1: '',
      quest2: '',
    },
    other: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
      quest5: '',
      quest6: '',
      quest7: '',
      quest8: '',
      quest9: '',
      quest10: '',
      quest11: '',
      quest12: '',
      quest13: '',
      quest14: '',
      quest15: '',
      quest16: '',
      quest17: '',
      quest18: '',
      quest19: '',
      quest20: '',
    }
  };

  const initialFormDataFilled = {
    personalInfo: {
      name: 'Rahadian Fujana',
      email: 'fuja@manna.id',
      phone: '081299900003',
      jumlahAnggota: '10',
      jumlahAnak: '2',
      memilikiRuangTamu: 'Ya',
      tinggalDenganOrangTua: 'Ya',
      memilikiRencana: 'Ya',
      biodata: 'Sample content text'
    },
    kamarUtama: {
      quest1: 'Sample content text',
      quest2: 'Sample content text 2',
      quest3: 'Sample content text 3',
      quest4: 'Sample content text 4',
      quest5: 'Meja rias berada di kamar utama',
      quest6: 'Meja kerja berada di kamar utama',
      quest7: 'Ya',
    },
    kamarAnak1: {
      quest1: 'Sample content text',
      quest2: 'Sample content text 2',
      quest3: 'Sample content text 3',
      quest4: 'Sample content text 4',
      quest5: '11',
    },
    kamarAnak2: {
      quest1: 'Sample content text',
      quest2: 'Sample content text 2',
      quest3: 'Sample content text 3',
      quest4: 'Sample content text 4',
      quest5: '9',
    },
    kamarAnak3: {
      quest1: 'Sample content text',
      quest2: 'Sample content text 2',
      quest3: 'Sample content text 3',
      quest4: 'Sample content text 4',
      quest5: '9',
    },
    kamarTamu: {
      quest1: 'Sample content text',
      quest2: 'Sample content text 2',
      quest3: 'Sample content text 3',
      quest4: 'Sample content text 4',
    },
    kamarOrangTua: {
      quest1: 'Sample content text 1',
      quest2: 'Sample content text 2',
      quest3: 'Sample content text 3',
      quest4: 'Sample content text 4',
    },
    ruangTamu: {
      quest1: 'Sample content text 1',
      quest2: 'Sample content text 2',
    },
    foyer: {
      quest1: 'Konsep Tertutup',
      quest2: 'Tidak',
      quest3: 'Ya',
    },
    ruangHobi: {
      quest1: 'Sample content text 1',
      quest2: 'Sample content text 2',
    },
    ruangMakan: {
      quest1: 'Sample content text 1',
      quest2: 'Meja makan berbentuk oval',
      quest3: 'Marmer',
      quest4: 'Ya',
      quest5: 'Sample content text 5',
      quest6: 'Sample content text 6',
      quest7: 'Sample content text 7',
      quest8: 'Sample content text 8',
      quest9: 'Sample content text 9',
      quest10: 'Sample content text 10',
      quest11: 'Sample content text 11',
    },
    ruangKerja: {
      quest1: 'Sample content text 1',
    },
    ruangBelajar: {
      quest1: 'Sample content text 1',
      quest2: 'Sample content text 2',
    },
    other: {
      quest1: 'Sample content text 1',
      quest2: 'Ya',
      quest3: 'Sample content text 3',
      quest4: 'Ya',
      quest5: 'Kulit',
      quest6: 'Ya',
      quest7: 'Tidak',
      quest8: 'Tidak',
      quest9: 'Tidak  ',
      quest10: 'Sample content text 10',
      quest11: 'Sample content text 11',
      quest12: 'Sample content text 12',
      quest13: 'Sample content text 13',
      quest14: 'Sample content text 14',
      quest15: 'Sample content text 15',
      quest16: 'Sample content text 16',
      quest17: 'Saya lebih suka memberikan masukan sepanjang proses design',
      quest18: 'Sample content text 18',
      quest19: 'Sample content text 19',
      quest20: 'Sample content text 20',
    }
  };

  const storedFormDataSaved = localStorage.getItem('formDataQuestionnaire');

  const [formData, setFormData] = useState(storedFormDataSaved ? JSON.parse(storedFormDataSaved) : initialFormData);
  // const [formData, setFormData] = useState(initialFormData);
  const [isBlocking, setIsBlocking] = useState(true);

  const [dataSection, setdataSection] = useState([
    "Personal Info Anda",
    "Kamar Utama",
    "Kamar Anak",
    "Kamar Tamu",
    "Ruang Orang Tua",
    "Ruang Tamu",
    "Foyer",
    "Ruang Hobi",
    "Ruang Makan dan Dapur",
    "Ruang Kerja",
    "Ruang Belajar",
    "Pertanyaan Lainnya",
  ]);

  // localStorage.setItem('formDataQuestionnaire', storedFormDataSaved ? JSON.parse(storedFormDataSaved) : JSON.stringify(initialFormData));

  const handleClickProceed = () => {
    setisProceed(true);
    document.body.style.overflow = 'auto'; // or 'scroll'

    setActiveIndex(0);
    settitleSectionName(`${dataSection[0]}`);
    setdisabledBtnNext(true);

    setTimeout(() => {
      setisShowFloating(true);
    }, 1000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Split the name into an array to access the nested structure
    const nameParts = name.split('.');

    if (nameParts[1] === undefined) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // Use the nameParts to update the formData
      setFormData((prevData) => ({
        ...prevData,
        [nameParts[0]]: {
          ...prevData[nameParts[0]],
          [nameParts[1]]: value,
        },
      }));
    }

    if (name === "personalInfo.tinggalDenganOrangTua") {
      sethasParentRoom(value === "Ya");
    }

    if (name === "personalInfo.memilikiRuangTamu") {
      sethasLivingRoom(value === "Ya");
    }

  };

  const handleBlur = (e) => {
    console.log(
      // '@@validateInput', validateInputs(activeIndex)
    );
  };

  const handleClickPrev = () => {
    scroller.scrollTo('formQuestionaire', {
      duration: 500, // Duration of the scroll animation in milliseconds
      smooth: 'easeInOutQuart', // Easing function for smooth scrolling
    });

    if (activeIndex === 2) {
      if (totalChild === 1) {
        setActiveIndex((prevIndex) =>  Math.max(prevIndex - 1, 0));
        settitleSectionName(`${dataSection[activeIndex - 1]}`);
      } else {
        settotalChild(totalChild - 1);
      }
    } else {
      setActiveIndex((prevIndex) =>  Math.max(prevIndex - 1, 0));
      settitleSectionName(`${dataSection[activeIndex - 1]}`);
    }

    setdisabledBtnNext(false);
  };

  const handleClickSelanjutnya = () => {
    // Validate the inputs before moving to the next slide
    scroller.scrollTo('formQuestionaire', {
      duration: 500, // Duration of the scroll animation in milliseconds
      smooth: 'easeInOutQuart', // Easing function for smooth scrolling
    });

    if (activeIndex === 2) {
      if (totalChild >= formData.personalInfo.jumlahAnak) {
        setActiveIndex((prevIndex) => Math.min(prevIndex + 1, totalSlides - 1));
        settitleSectionName(`${dataSection[activeIndex + 1]}`);
        setdisabledBtnNext(true);
      } else {
        settotalChild(totalChild + 1);
      }
    } else if (activeIndex === (hasParentRoom && hasLivingRoom ? 11 : (!hasParentRoom && hasLivingRoom) || (hasParentRoom && !hasLivingRoom) ? 10 : 9)) {
      setisShowFloating(false);
      sessionStorage.setItem('data', JSON.stringify(formData));

      scroller.scrollTo('formQuestionaire', {
        duration: 500, // Duration of the scroll animation in milliseconds
        smooth: 'easeInOutQuart', // Easing function for smooth scrolling
      });

      setTimeout(() => {
        setisFinished(true);
        localStorage.removeItem('formDataQuestionnaire');
        handleSendEmail();
        localStorage.setItem('finished', true);
        document.body.style.overflow = 'hidden';
      }, 600);

    } else {
      if (validateInputs(activeIndex)) {
        setActiveIndex((prevIndex) => {
          const numberVlida = prevIndex === 10 && (hasParentRoom && hasLivingRoom) ? 11 : Math.min(prevIndex + 1, totalSlides - 1);
          return numberVlida;
        });
        settitleSectionName(`${dataSection[activeIndex + 1]}`);
        setdisabledBtnNext(true);
      } else {
        alert('Data yang diperlukan belum diisi. Harap isi seluruh data untuk dapat melanjutkan');
        document.body.style.overflow = 'auto'; // or 'scroll'
      }
    }
  };

  const validateInputs = (index) => {
    // Get the slider-wrapper element corresponding to the active index
    const sliderWrapper = document.getElementsByClassName('slider-wrapper')[index];
    const sliderId = sliderWrapper.id;
    let isFilled = false;

    isFilled = Object.values(formData[sliderId]).every(value => value !== '');

    if (isFilled) {
      return true;
    } else {
      return false;
    }
  };

  const validateFilledInputs = (obj) => {
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (!validateFilledInputs(obj[key])) return false;
      } else if (obj[key] !== '') {
        return false;
      }
    }
    return true;
  };

  const handleSendEmail = async () => {
    try {
      const htmlContent = `
        Hello Manna Interior Team,
        Tuan/Nyonya ${formData.personalInfo.name} baru saja mengisi form kuisioner Manna Interior. Berikut ini data lengkapnya :

        Personal Info :
        ====================================
        Nama : ${formData.personalInfo.name}
        Email : ${formData.personalInfo.email}
        Nomor Telepon : ${formData.personalInfo.phone}
        Berapa Jumlah Anggota Keluarga Anda Saat ini? : ${formData.personalInfo.jumlahAnggota}
        Berapa Jumlah Anak Anda Saat ini? : ${formData.personalInfo.jumlahAnak}
        Apakah Anda memiliki rencana untuk menambah anggota keluarga? : ${formData.personalInfo.memilikiRencana}
        Tuliskan bidodata singkat mengenai (umur,hobby,koleksi,minat,dan warna ) kesukaan dari anggota keluarga Anda, yaitu Ayah,ibu,anak 1, anak 2, anak 3, dan orang tua :
        ${formData.personalInfo.biodata}

        Kamar Utama :
        ====================================
        Deskripsikan style yang Anda inginkan untuk Kamar Utama
        ${formData.kamarUtama.quest1}

        Apakah Anda memerlukan meja kerja,cermin rias, sofa, kursi, dan TV pada area kamar tidur? *Contoh: saya hanya memerlukan meja kerja dan TV
        ${formData.kamarUtama.quest2}

        Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu
        ${formData.kamarUtama.quest3}

        Apa warna yang anda sukai dan tidak sukai pada kamar?
        ${formData.kamarUtama.quest4}

        Manakah yang lebih Anda sukai?
        - ${formData.kamarUtama.quest5}
        - ${formData.kamarUtama.quest6}

        Apakah Anda menginginkan area untuk menaruh brankas?
        ${formData.kamarUtama.quest7}

        Kamar Anak ke-1 :
        ====================================
        Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar anak ke 1?
        ${formData.kamarAnak1.quest1}

        Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar anak 1?
        ${formData.kamarAnak1.quest2}

        Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.
        ${formData.kamarAnak1.quest3}

        Warna apa yang Anda sukai dan tidak sukai pada kamar?
        ${formData.kamarAnak1.quest4}

        Kamar Anak ke-2 :
        ====================================

        Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar anak ke 2?
        ${formData.kamarAnak2.quest1 !== "" ? formData.kamarAnak2.quest1 : '-'}

        Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar anak 2?
        ${formData.kamarAnak2.quest2 !== "" ? formData.kamarAnak2.quest2 : '-'}

        Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.
        ${formData.kamarAnak2.quest3 !== "" ? formData.kamarAnak2.quest3 : '-'}

        Warna apa yang Anda sukai dan tidak sukai pada kamar?
        ${formData.kamarAnak2.quest4 !== "" ? formData.kamarAnak2.quest4 : '-'}

        Kamar Anak ke-3 :
        ====================================

        Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar anak ke 3?
        ${formData.kamarAnak3.quest1 !== "" ? formData.kamarAnak3.quest1 : '-'}

        Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar anak 3?
        ${formData.kamarAnak3.quest2 !== "" ? formData.kamarAnak3.quest2 : '-'}

        Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.
        ${formData.kamarAnak3.quest3 !== "" ? formData.kamarAnak3.quest3 : '-'}

        Warna apa yang Anda sukai dan tidak sukai pada kamar?
        ${formData.kamarAnak3.quest4 !== "" ? formData.kamarAnak3.quest4 : '-'}

        Kamar Tamu :
        ====================================

        Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar tamu?
        ${formData.kamarTamu.quest1 !== "" ? formData.kamarTamu.quest1 : '-'}

        Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar tamu?
        ${formData.kamarTamu.quest2 !== "" ? formData.kamarTamu.quest2 : '-'}

        Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.
        ${formData.kamarTamu.quest3 !== "" ? formData.kamarTamu.quest3 : '-'}

        Warna apa yang Anda sukai dan tidak sukai pada kamar?
        ${formData.kamarTamu.quest4 !== "" ? formData.kamarTamu.quest4 : '-'}

        Kamar Orang Tua :
        ====================================

        Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar orang tua?
        ${formData.kamarOrangTua.quest1 !== "" ? formData.kamarOrangTua.quest1 : '-'}

        Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar orang tua?
        ${formData.kamarOrangTua.quest2 !== "" ? formData.kamarOrangTua.quest2 : '-'}

        Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.
        ${formData.kamarOrangTua.quest3 !== "" ? formData.kamarOrangTua.quest3 : '-'}

        Warna apa yang Anda sukai dan tidak sukai pada kamar?
        ${formData.kamarOrangTua.quest4 !== "" ? formData.kamarOrangTua.quest4 : '-'}

        Ruang Tamu :
        ====================================

        Apa saja yang Anda butuhkan selain sofa dan TV kabinet? Apakah Anda memerlukan sofa L Shape atau tidak? Dan sebutkan ukuruan TV yang Anda butuhkan
        ${formData.ruangTamu.quest1}

        Aktivitas apa saja yang biasanya Anda lakukan di ruang tamu? dan sebutkan kegiatannya. Misal: Meminum teh, relaksasi, atau bekerja.
        ${formData.ruangTamu.quest2}

        Foyer :
        ====================================

        Manakah yang lebih Anda sukai untuk area foyer?
        ${formData.foyer.quest1}

        Apakah pada area foyer Anda memerlukan tempat duduk?
        ${formData.foyer.quest2}

        Apakah Anda membutuhkan lemari sepatu?
        ${formData.foyer.quest3}

        Ruang Hobi :
        ====================================

        Apabila terdapat ruang hobi, apa saja aktivitas dan kebutuhan Anda pada ruang hobi?
        ${formData.ruangHobi.quest1}

        Apa saja perabotan dan elektronik yang Anda butuhkan pada ruang hobi?
        ${formData.ruangHobi.quest2}

        Ruang Makan :
        ====================================

        Meja makan untuk berapa orang yang Anda butuhkan?
        ${formData.ruangMakan.quest1}

        Manakah dibawah ini yang lebih Anda sukai?
        ${formData.ruangMakan.quest2}

        Manakah finishing meja makan yang lebih Anda sukai untuk top table?
        ${formData.ruangMakan.quest3}

        Apakah Anda memerlukan tempat untuk penyimpanan piring, gelas, sendok di area makan? Serta dispenser, kulkas  di sekitar meja makan?
        ${formData.ruangMakan.quest4}

        Apakah Anda akan melakukan aktivitas di dapur, selain memasak makanan?
        ${formData.ruangMakan.quest5}

        Apa saja yang Anda butuhkan di dapur? Misalkan: kulkas 2 pintu , microwave, oven, dsb.
        ${formData.ruangMakan.quest6}

        Apakah Anda menyukai island cabinet? Jika iya, apakah island cabinet akan dipergunakan untuk kompor atau sink atau polos saja?
        ${formData.ruangMakan.quest7}

        Tinggi island yang anda inginkan, apakah setinggi meja makan standar atau meja bar?
        ${formData.ruangMakan.quest8}

        Apakah Island cabinet di pergunakan untuk makan atau hanya untuk kegiatan memasak saja?
        ${formData.ruangMakan.quest9}

        Apakah Anda mempunyai dapur kotor? Jika iya, sebutkan Peralatan elektronik apa saja yang yang ada di dapur kotor!
        ${formData.ruangMakan.quest10}

        Apakah Anda memerlukan area Bar? Jika iya, kebutuhannya apa saja yang Anda perlukan? Misal: Wine Chiller , coffee maker, tempat menaruh gelas dan botol wine, dsb.
        ${formData.ruangMakan.quest11}

        Ruang Kerja :
        ====================================

        Apakah Anda memerlukan ruang kerja khusus?Jika iya, apakah ruangan kerja Anda ingin terlihat formal atau santai? Dan sebutkan kebutuhan apa saja yang Anda perlukan. Misal: TV, Kulkas, Dispenser, Sofa , brankas,dsb.
        ${formData.ruangKerja.quest1}

        Ruang Belajar :
        ====================================

        Apakah Anda memerlukan ruang belajar khusus untuk anak atau les privat? Jika iya, sebutkan kebutuhan apa saja yang Anda perlukan!
        ${formData.ruangBelajar.quest1}

        Apakah Anda memerlukan lemari buku? Jika Iya, mana yang lebih Anda sukai, menggunakan pintu terbuka atau tertutup?
        ${formData.ruangBelajar.quest2}

        Lainnya :
        ====================================

        Apakah Anda menerapkan ilmu Fengshui? Jika iya, apa saja yang diterapkan?
        ${formData.other.quest1}

        Apakah Anda suka dengan permainan cermin?
        ${formData.other.quest2}

        Ruang mana yang menjadi prioritas Anda untuk berkumpul bersama keluarga? Dan sebutkan kebutuhan yang Anda perlukan!
        ${formData.other.quest3}

        Apakah Anda suka pada permainan panel dinding?
        ${formData.other.quest4}

        Manakah yang lebih Anda sukai, sebagai bahan pelapis sofa dan kepala ranjang?
        ${formData.other.quest5}

        Apakah Anda menyukai permainan drop ceiling?
        ${formData.other.quest6}

        Apakah kami boleh merubah lantai existing?
        ${formData.other.quest7}

        Apakah kami boleh merubah dinding?
        ${formData.other.quest8}

        Apakah kami perlu merubah kamar mandi existing?
        ${formData.other.quest9}

        Sebutkan bahan yang Anda inginkan dalam membentuk railing tangga! Dengan konsep terbuka atau tertutup?
        ${formData.other.quest10}

        Sebutkan permasalahan apa saja yang terjadi pada rumah sebelumnya? Sertakan dengan hal-hal yang ingin Anda ingin dihindari pada rumah baru.
        ${formData.other.quest11}

        Apakah Anda mempunyai elemen di rumah lama yang ingin tetap di adakan? Misal: piano, barang pajangan,dsb.
        ${formData.other.quest12}

        Sebutkan elemen yang ingin Anda utamakan pada rumah ini? Misal: Kayu,air,dan batu
        ${formData.other.quest13}

        Warna yang Anda inginkan untuk mendominasi rumah?
        ${formData.other.quest14}

        Apakah Anda menginginkan loose furniture yang dibuat khusus, atau beli jadi?Jika beli, apa saja yang ingin Anda beli dan sebutkan merek apa saja yang Anda ketahui?
        ${formData.other.quest15}

        Dimanakah Anda akan menaruh peralatan untuk bersih-bersih rumah? Apakah di setiap lantai, atau diletakkan di ruangan service?Dan sebutkan letak posisi dari Arsiteknya.
        ${formData.other.quest16}

        Sejauh mana Anda ingin terlibat dalam proses desain?
        ${formData.other.quest17}

        Apakah Anda memiliki batasan waktu yang perlu diperhatikan untuk menyelesaikan proyek desain ini?
        ${formData.other.quest18}

        Deskripsikan rumah impian yang Anda inginkan!
        ${formData.other.quest19}

        Tuliskan tiga kata yang ingin Anda gambarkan ketika orang masuk ke dalam rumah Anda !
        ${formData.other.quest20}

        Terima kasih!
      `;

      const templateParams = {
        from_name: `${formData.personalInfo.name}`,
        to_name: 'Manna Interior Team',
        html: htmlContent
      };

      const serviceID = 'service_02xjltq';
      const templateID = 'template_6woh722';
      const userID = '_niUb8iq8liP1ic3g'; // Use your User ID here

      const response = await emailjs.send(serviceID, templateID, templateParams, userID);

    } catch (error) {
      console.error('Email failed to send:', error);
    }
  };

  const renderPersonalInfo = () => {
    return (
      <>
        <InputForm type='text' variant='text-top' label='Nama Lengkap' name='personalInfo.name' value={formData.personalInfo.name} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm type='email' variant='text-top' label='Alamat Email' name='personalInfo.email' value={formData.personalInfo.email} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm type='text' variant='text-top' label='Nomor Telepon' name='personalInfo.phone' value={formData.personalInfo.phone} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm
          type='number'
          variant='text-top'
          label='Berapa Jumlah Anggota Keluarga Anda Saat ini?'
          labelError='Jumlah Anggota'
          name='personalInfo.jumlahAnggota'
          value={formData.personalInfo.jumlahAnggota}
          onChange={handleChange} onBlur={handleBlur}
          mandatory
        />
        <InputForm
          type='number'
          maxLength={1}
          variant='text-top'
          label='Berapa Jumlah Anak Anda Saat ini?'
          labelError='Jumlah Anak'
          name='personalInfo.jumlahAnak'
          value={formData.personalInfo.jumlahAnak}
          onChange={handleChange} onBlur={handleBlur}
          mandatory
        />
        <InputGroup className='mb-40 mb-m-16' label="Apakah Anda memiliki rencana untuk membuat kamar khusus untuk orang tua?">
          <Fragment>
            <RadioButton checked={formData.personalInfo.tinggalDenganOrangTua === "Ya"} className='dib mr-24' name="personalInfo.tinggalDenganOrangTua" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.personalInfo.tinggalDenganOrangTua === "Tidak"} className='dib mr-24' name="personalInfo.tinggalDenganOrangTua" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40 mb-m-16' label="Apakah Anda memiliki rencana untuk memiliki kamar tamu?">
          <Fragment>
            <RadioButton checked={formData.personalInfo.memilikiRuangTamu === "Ya"} className='dib mr-24' name="personalInfo.memilikiRuangTamu" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.personalInfo.memilikiRuangTamu === "Tidak"} className='dib mr-24' name="personalInfo.memilikiRuangTamu" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40 mb-m-16' label="Apakah Anda memiliki rencana untuk menambah anggota keluarga?">
          <Fragment>
            <RadioButton checked={formData.personalInfo.memilikiRencana === "Ya"} className='dib mr-24' name="personalInfo.memilikiRencana" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.personalInfo.memilikiRencana === "Tidak"} className='dib mr-24' name="personalInfo.memilikiRencana" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.personalInfo.memilikiRencana === `Lainnya: ${formData.personalInfo.memilikiRencana}`} className='dib mr-24' name="personalInfo.memilikiRencana" value={`Lainnya: ${formData.personalInfo.memilikiRencana}`} onChange={handleChange} onBlur={handleBlur} label="Lainnya" defaultSelected={false} withText={true} />
          </Fragment>
        </InputGroup>
        <InputForm
          type='text'
          variant='text-top'
          label='Tuliskan bidodata singkat mengenai (umur,hobby,koleksi,minat,dan warna ) kesukaan dari anggota keluarga Anda, yaitu Ayah,ibu,anak 1, anak 2, anak 3, dan orang tua.'
          labelError='Biodata'
          name='personalInfo.biodata'
          value={formData.personalInfo.biodata}
          onChange={handleChange} onBlur={handleBlur}
          mandatory
        />
      </>
    )
  };

  const renderKamarUtama = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Deskripsikan style yang Anda inginkan untuk Kamar Utama' name='kamarUtama.quest1' value={formData.kamarUtama.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja kerja,cermin rias, sofa, kursi, dan TV pada area kamar tidur? *Contoh: saya hanya memerlukan meja kerja dan TV' name='kamarUtama.quest2' value={formData.kamarUtama.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu' name='kamarUtama.quest3' value={formData.kamarUtama.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apa warna yang anda sukai dan tidak sukai pada kamar?' name='kamarUtama.quest4' value={formData.kamarUtama.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Manakah yang lebih Anda sukai?">
          <Fragment>
            <RadioButton checked={formData.kamarUtama.quest5 === "Meja rias berada di kamar utama"} className='dib mr-24' name="kamarUtama.quest5" value="Meja rias berada di kamar utama" label="Meja rias berada di kamar utama" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <br/>
            <RadioButton checked={formData.kamarUtama.quest5 === "Meja rias berada pada Walk In Closet"} className='dib mr-24' name="kamarUtama.quest5" value="Meja rias berada pada Walk In Closet" label="Meja rias berada pada Walk In Closet" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Manakah yang lebih Anda sukai?">
          <Fragment>
            <RadioButton checked={formData.kamarUtama.quest6 === "Meja kerja berada di kamar utama"} className='dib mr-24' name="kamarUtama.quest6" value="Meja kerja berada di kamar utama" label="Meja kerja berada di kamar utama" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <br/>
            <RadioButton checked={formData.kamarUtama.quest6 === "Meja kerja memiliki ruangan tersendiri"} className='dib mr-24' name="kamarUtama.quest6" value="Meja kerja memiliki ruangan tersendiri" label="Meja kerja memiliki ruangan tersendiri" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah Anda menginginkan area untuk menaruh brankas?">
          <Fragment>
            <RadioButton checked={formData.kamarUtama.quest7 === "Ya"} className='dib mr-24' name="kamarUtama.quest7" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.kamarUtama.quest7 === "Tidak"} className='dib mr-24' name="kamarUtama.quest7" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
      </>
    );
  };
  const renderKamarAnak1 = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar anak ke 1?' name='kamarAnak1.quest1' value={formData.kamarAnak1.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar anak 1?' name='kamarAnak1.quest2' value={formData.kamarAnak1.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarAnak1.quest3' value={formData.kamarAnak1.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarAnak1.quest4' value={formData.kamarAnak1.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='number' maxLength={2} labelError='ini' label='Berapakah umur anak ke-1?' name='kamarAnak1.quest5' value={formData.kamarAnak1.quest5} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderKamarAnak2 = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar anak ke 2?' name='kamarAnak2.quest1' value={formData.kamarAnak2.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar anak 2?' name='kamarAnak2.quest2' value={formData.kamarAnak2.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarAnak2.quest3' value={formData.kamarAnak2.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarAnak2.quest4' value={formData.kamarAnak2.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='number' maxLength={2} labelError='ini' label='Berapakah umur anak ke-2?' name='kamarAnak2.quest5' value={formData.kamarAnak2.quest5} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderKamarAnak3 = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar anak ke 3?' name='kamarAnak3.quest1' value={formData.kamarAnak3.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar anak 3?' name='kamarAnak3.quest2' value={formData.kamarAnak3.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarAnak3.quest3' value={formData.kamarAnak3.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarAnak3.quest4' value={formData.kamarAnak3.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='number' maxLength={2} labelError='ini' label='Berapakah umur anak ke-3?' name='kamarAnak3.quest5' value={formData.kamarAnak3.quest5} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderKamarTamu = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar tamu?' name='kamarTamu.quest1' value={formData.kamarTamu.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar tamu?' name='kamarTamu.quest2' value={formData.kamarTamu.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarTamu.quest3' value={formData.kamarTamu.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarTamu.quest4' value={formData.kamarTamu.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderKamarOrangTua = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar orang tua?' name='kamarOrangTua.quest1' value={formData.kamarOrangTua.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar orang tua?' name='kamarOrangTua.quest2' value={formData.kamarOrangTua.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarOrangTua.quest3' value={formData.kamarOrangTua.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarOrangTua.quest4' value={formData.kamarOrangTua.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderRuangTamu = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apa saja yang Anda butuhkan selain sofa dan TV kabinet? Apakah Anda memerlukan sofa L Shape atau tidak? Dan sebutkan ukuruan TV yang Anda butuhkan' name='ruangTamu.quest1' value={formData.ruangTamu.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Aktivitas apa saja yang biasanya Anda lakukan di ruang tamu? dan sebutkan kegiatannya. Misal: Meminum teh, relaksasi, atau bekerja.' name='ruangTamu.quest2' value={formData.ruangTamu.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderFoyer = () => {
    return (
      <>
        <InputGroup className='mb-40' label="Manakah yang lebih Anda sukai untuk area foyer?">
          <Fragment>
            <RadioButton checked={formData.foyer.quest1 === "Konsep Open Space"} className='dib mr-24' name="foyer.quest1" value="Konsep Open Space" label="Konsep Open Space" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.foyer.quest1 === "Konsep Tertutup"} className='dib mr-24' name="foyer.quest1" value="Konsep Tertutup" label="Konsep Tertutup" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah pada area foyer Anda memerlukan tempat duduk? ">
          <Fragment>
            <RadioButton checked={formData.foyer.quest2 === "Ya"} className='dib mr-24' name="foyer.quest2" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.foyer.quest2 === "Tidak"} className='dib mr-24' name="foyer.quest2" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah Anda membutuhkan lemari sepatu?">
          <Fragment>
            <RadioButton checked={formData.foyer.quest3 === "Ya"} className='dib mr-24' name="foyer.quest3" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.foyer.quest3 === "Tidak"} className='dib mr-24' name="foyer.quest3" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
      </>
    );
  };
  const renderRuangHobi = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apabila terdapat ruang hobi, apa saja aktivitas dan kebutuhan Anda pada ruang hobi?' name='ruangHobi.quest1' value={formData.ruangHobi.quest1} onChange={handleChange} onBlur={handleBlur} mandatory /><br/>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apa saja perabotan dan elektronik yang Anda butuhkan pada ruang hobi?' name='ruangHobi.quest2' value={formData.ruangHobi.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderRuangMakan = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Meja makan untuk berapa orang yang Anda butuhkan?' name='ruangMakan.quest1' value={formData.ruangMakan.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Manakah dibawah ini yang lebih Anda sukai?">
          <Fragment>
            <RadioButton checked={formData.ruangMakan.quest2 === "Meja makan berbentuk oval"} className='dib mr-24' name="ruangMakan.quest2" value="Meja makan berbentuk oval" label="Meja makan berbentuk oval " onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.ruangMakan.quest2 === "Meja makan berbentuk bulat"} className='dib mr-24' name="ruangMakan.quest2" value="Meja makan berbentuk bulat" label="Meja makan berbentuk bulat" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.ruangMakan.quest2 === "Meja makan berbentuk kotak"} className='dib mr-24' name="ruangMakan.quest2" value="Meja makan berbentuk kotak" label="Meja makan berbentuk kotak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Manakah finishing meja makan yang lebih Anda sukai untuk top table?">
          <Fragment>
            <RadioButton checked={formData.ruangMakan.quest3 === "Marmer"} className='dib mr-24' name="ruangMakan.quest3" value="Marmer" label="Marmer" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.ruangMakan.quest3 === "Kaca"} className='dib mr-24' name="ruangMakan.quest3" value="Kaca" label="Kaca" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.ruangMakan.quest3 === "Kayu solid"} className='dib mr-24' name="ruangMakan.quest3" value="Kayu solid" label="Kayu solid" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah Anda memerlukan tempat untuk penyimpanan piring, gelas, sendok di area makan? Serta dispenser, kulkas  di sekitar meja makan?">
          <Fragment>
            <RadioButton checked={formData.ruangMakan.quest4 === "Ya"} className='dib mr-24' name="ruangMakan.quest4" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.ruangMakan.quest4 === "Tidak"} className='dib mr-24' name="ruangMakan.quest4" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda akan melakukan aktivitas di dapur, selain memasak makanan?' name='ruangMakan.quest5' value={formData.ruangMakan.quest5} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apa saja yang Anda butuhkan di dapur? Misalkan: kulkas 2 pintu , microwave, oven, dsb.' name='ruangMakan.quest6' value={formData.ruangMakan.quest6} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai island cabinet? Jika iya, apakah island cabinet akan dipergunakan untuk kompor atau sink atau polos saja?' name='ruangMakan.quest7' value={formData.ruangMakan.quest7} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Tinggi island yang anda inginkan, apakah setinggi meja makan standar atau meja bar?' name='ruangMakan.quest8' value={formData.ruangMakan.quest8} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Island cabinet di pergunakan untuk makan atau hanya untuk kegiatan memasak saja?' name='ruangMakan.quest9' value={formData.ruangMakan.quest9} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda mempunyai dapur kotor? Jika iya, sebutkan Peralatan elektronik apa saja yang yang ada di dapur kotor!' name='ruangMakan.quest10' value={formData.ruangMakan.quest10} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan area Bar? Jika iya, kebutuhannya apa saja yang Anda perlukan? Misal: Wine Chiller , coffee maker, tempat menaruh gelas dan botol wine, dsb.' name='ruangMakan.quest11' value={formData.ruangMakan.quest11} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderRuangKerja = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan ruang kerja khusus?Jika iya, apakah ruangan kerja Anda ingin terlihat formal atau santai? Dan sebutkan kebutuhan apa saja yang Anda perlukan. Misal: TV, Kulkas, Dispenser, Sofa , brankas,dsb.' name='ruangKerja.quest1' value={formData.ruangKerja.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderRuangBelajar = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan ruang belajar khusus untuk anak atau les privat? Jika iya, sebutkan kebutuhan apa saja yang Anda perlukan!' name='ruangBelajar.quest1' value={formData.ruangBelajar.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan lemari buku? Jika Iya, mana yang lebih Anda sukai, menggunakan pintu terbuka atau tertutup?' name='ruangBelajar.quest2' value={formData.ruangBelajar.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  // const renderRuangLuar = () => {
  //   return (
  //     <>
  //       <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda membutuhkan ruang luar? Jika Iya, kebutuhan apa saja yang Anda butuhkan? Misal: tempat duduk, alat BBQ, dsb.' name='ruangLuar.quest1' value={formData.ruangLuar.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
  //       <InputGroup className='mb-40' label="Manakah yang Anda inginkan?">
  //         <Fragment>
  //           <RadioButton checked={formData.ruangLuar.quest2 === "Tanaman Menggunakan Pot"} className='dib mr-24' name="ruangLuar.quest2" value="Tanaman Menggunakan Pot" label="Tanaman Menggunakan Pot" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
  //           <RadioButton checked={formData.ruangLuar.quest2 === "Tanaman Menggunakan Planter Box"} className='dib mr-24' name="ruangLuar.quest2" value="Tanaman Menggunakan Planter Box" label="Tanaman Menggunakan Planter Box" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
  //         </Fragment>
  //       </InputGroup>
  //     </>
  //   );
  // };
  const renderOthers = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menerapkan ilmu Fengshui? Jika iya, apa saja yang diterapkan?' name='other.quest1' value={formData.other.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Apakah Anda suka dengan permainan cermin?">
          <Fragment>
            <RadioButton checked={formData.other.quest2 === "Ya"} className='dib mr-24' name="other.quest2" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.other.quest2 === "Tidak"} className='dib mr-24' name="other.quest2" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputForm variant="text-top" type='text' labelError='ini' label='Ruang mana yang menjadi prioritas Anda untuk berkumpul bersama keluarga? Dan sebutkan kebutuhan yang Anda perlukan!' name='other.quest3' value={formData.other.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Apakah Anda suka pada permainan panel dinding?">
          <Fragment>
            <RadioButton checked={formData.other.quest4 === "Ya"} className='dib mr-24' name="other.quest4" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.other.quest4 === "Saya lebih menyukai menggunakan cat"} className='dib mr-24' name="other.quest4" value="Saya lebih menyukai menggunakan cat" label="Saya lebih menyukai menggunakan cat" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.other.quest4 === "Saya lebih menyukai menggunakan wallpaper"} className='dib mr-24' name="other.quest4" value="Saya lebih menyukai menggunakan wallpaper" label="Saya lebih menyukai menggunakan wallpaper" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.other.quest4 === "Saya lebih menyukai menggunakan cat texture"} className='dib mr-24' name="other.quest4" value="Saya lebih menyukai menggunakan cat texture" label="Saya lebih menyukai menggunakan cat texture" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Manakah yang lebih Anda sukai, sebagai bahan pelapis sofa dan kepala ranjang?">
          <Fragment>
            <RadioButton checked={formData.other.quest5 === "Kulit"} className='dib mr-24' name="other.quest5" value="Kulit" label="Kulit" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton checked={formData.other.quest5 === "Fabric"} className='dib mr-24' name="other.quest5" value="Fabric" label="Fabric" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah Anda menyukai permainan drop ceiling?">
          <Fragment>
            <RadioButton checked={formData.other.quest6 === "Ya"} className='dib mr-24' name="other.quest6" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.other.quest6 === "Tidak"} className='dib mr-24' name="other.quest6" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah kami boleh merubah lantai existing?">
          <Fragment>
            <RadioButton checked={formData.other.quest7 === "Ya"} className='dib mr-24' name="other.quest7" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.other.quest7 === "Tidak"} className='dib mr-24' name="other.quest7" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah kami boleh merubah dinding?">
          <Fragment>
            <RadioButton checked={formData.other.quest8 === "Ya"} className='dib mr-24' name="other.quest8" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.other.quest8 === "Tidak"} className='dib mr-24' name="other.quest8" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah kami perlu merubah kamar mandi existing?">
          <Fragment>
            <RadioButton checked={formData.other.quest9 === "Ya"} className='dib mr-24' name="other.quest9" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.other.quest9 === "Tidak"} className='dib mr-24' name="other.quest9" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan bahan yang Anda inginkan dalam membentuk railing tangga! Dengan konsep terbuka atau tertutup?' name='other.quest10' value={formData.other.quest10} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan permasalahan apa saja yang terjadi pada rumah sebelumnya? Sertakan dengan hal-hal yang ingin Anda ingin dihindari pada rumah baru.' name='other.quest11' value={formData.other.quest11} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda mempunyai elemen di rumah lama yang ingin tetap di adakan? Misal: piano, barang pajangan,dsb.' name='other.quest12' value={formData.other.quest12} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan elemen yang ingin Anda utamakan pada rumah ini? Misal: Kayu,air,dan batu' name='other.quest13' value={formData.other.quest13} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna yang Anda inginkan untuk mendominasi rumah?' name='other.quest14' value={formData.other.quest14} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menginginkan loose furniture yang dibuat khusus, atau beli jadi?Jika beli, apa saja yang ingin Anda beli dan sebutkan merek apa saja yang Anda ketahui?' name='other.quest15' value={formData.other.quest15} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Dimanakah Anda akan menaruh peralatan untuk bersih-bersih rumah? Apakah di setiap lantai, atau diletakkan di ruangan service?Dan sebutkan letak posisi dari Arsiteknya.' name='other.quest16' value={formData.other.quest16} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Sejauh mana Anda ingin terlibat dalam proses desain?">
          <Fragment>
            <RadioButton checked={formData.other.quest17 === "Saya lebih suka memberikan masukan sepanjang proses design"} className='dib mr-24' name="other.quest17" value="Saya lebih suka memberikan masukan sepanjang proses design" label="Saya lebih suka memberikan masukan sepanjang proses design" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton checked={formData.other.quest17 === "Saya lebih suka memberikan kebebasan penuh kepada designer"} className='dib mr-24' name="other.quest17" value="Saya lebih suka memberikan kebebasan penuh kepada designer" label="Saya lebih suka memberikan kebebasan penuh kepada designer" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memiliki batasan waktu yang perlu diperhatikan untuk menyelesaikan proyek desain ini?' name='other.quest18' value={formData.other.quest18} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Deskripsikan rumah impian yang Anda inginkan!' name='other.quest19' value={formData.other.quest19} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Tuliskan tiga kata yang ingin Anda gambarkan ketika orang masuk ke dalam rumah Anda !' name='other.quest20' value={formData.other.quest20} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };

  // useEffect
  useEffect(() => {
    const isFinished = localStorage.getItem('finished');

    if (isFinished === 'true') {
      sethasFilled(isFinished);
    } else {
      sethasFilled(false);
    }

    setTimeout(() => {
      sethideLoader(true);
    }, 3500);
  }, []);

  useEffect(() => {
    if (activeIndex !== 2) {
      setTimeout(() => {
        if (validateInputs(activeIndex)) {
          setdisabledBtnNext(!validateInputs(activeIndex));
        } else {
          setdisabledBtnNext(true);
        }
      }, 200);
    }
  }, [handleChange, handleClickSelanjutnya]);

  useEffect(() => {
    if (activeIndex === 2) {
      setTimeout(() => {
        const isKamarAnakValid = Object.values(formData[`kamarAnak${totalChild}`]).every(value => value !== '');

        if (isKamarAnakValid) {
          // All keys in kamarAnak${totalChild} have values
          setdisabledBtnNext(!isKamarAnakValid);
        } else {
          // Some keys in kamarAnak${totalChild} are missing values
          setdisabledBtnNext(true);
        }
      }, 200);
    }
    const isNotBlocked = validateFilledInputs(formData);
    setIsBlocking(isNotBlocked);

    localStorage.setItem('formDataQuestionnaire', JSON.stringify(formData));

    const storedFormData = localStorage.getItem('formDataQuestionnaire');

    // Check if the data exists in localStorage
    if (storedFormData) {
      // Parse the JSON string to convert it back to a JavaScript object
      const formData = JSON.parse(storedFormData);

      // Access the desired value from the object
      const personalInfoName = formData.personalInfo.name;

      console.log(`the value ${personalInfoName}`); // Output the value of personalInfo.name
    }
    
  }, [handleChange]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(() => {
    if (hasParentRoom && hasLivingRoom) {
      setdataSection([
        "Personal Info Anda",
        "Kamar Utama",
        "Kamar Anak",
        "Kamar Tamu",
        "Ruang Orang Tua",
        "Ruang Tamu",
        "Foyer",
        "Ruang Hobi",
        "Ruang Makan dan Dapur",
        "Ruang Kerja",
        "Ruang Belajar",
        "Pertanyaan Lainnya",
      ]);
    } else if (hasParentRoom && !hasLivingRoom) {
      setdataSection([
        "Personal Info Anda",
        "Kamar Utama",
        "Kamar Anak",
        "Ruang Orang Tua",
        "Ruang Tamu",
        "Foyer",
        "Ruang Hobi",
        "Ruang Makan dan Dapur",
        "Ruang Kerja",
        "Ruang Belajar",
        "Pertanyaan Lainnya",
      ]);
    } else if (!hasParentRoom && hasLivingRoom) {
      setdataSection([
        "Personal Info Anda",
        "Kamar Utama",
        "Kamar Anak",
        "Kamar Tamu",
        "Ruang Tamu",
        "Foyer",
        "Ruang Hobi",
        "Ruang Makan dan Dapur",
        "Ruang Kerja",
        "Ruang Belajar",
        "Pertanyaan Lainnya",
      ]);
    } else {
      setdataSection([
        "Personal Info Anda",
        "Kamar Utama",
        "Kamar Anak",
        "Ruang Tamu",
        "Foyer",
        "Ruang Hobi",
        "Ruang Makan dan Dapur",
        "Ruang Kerja",
        "Ruang Belajar",
        "Pertanyaan Lainnya",
      ]);
    }
  }, [hasParentRoom, hasLivingRoom]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        const confirmLeave = window.confirm('Are you sure you want to leave this page?');
        if (!confirmLeave) {
          history.push(history.location.pathname);
          return false;
        }
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isBlocking) {
        const message = 'Are you sure you want to leave this page?';
        event.returnValue = message; // For most browsers
        return message; // For some legacy browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isBlocking]);

  return (
    <>
      {/* for every transition page  */}
      <section className={`loader ${isHideLoader}`}>
        <div className='container'>
          <img src={imageUrls.gifLoaderLogo} alt='Logo' />
        </div>
      </section>

      <section className={`opener ${isHideSectionOpener}`}>
        <div className='img-bg'></div>
        <div className='container'>
          <div className='row'>
            <div className={`col col-d-60 col-m-80 h-center vh-100 section-divider`}>
              <div className='v-center has-cover ta-l ta-m-c'>
                {hasFilled? (
                  <>
                    <h1 className='a-h1 mb-16 mb-32'>
                      Anda sudah mengisi kuisioner ini.
                    </h1>
                    <p className='a-p mb-40'>
                      Anda hanya dapat mengisi formulir ini secara satu kali saja. <br/>
                      Coba hubungi pemilik formulir ini jika menurut Anda hal ini adalah kesalahan.
                    </p>
                  </>
                ): (
                  <>
                    <h1 className='a-h1 mb-24 ta-l light'>
                      Dear, <br/>
                      <b>
                        Kustomer Manna Interior
                      </b>
                    </h1>
                    <p className='a-p mb-40 mb-m-16 ta-l'>
                      Mohon jawab semua pertanyaan, sesuai dengan apa yang Anda sukai dan butuhkan. <br/>Agar kami dapat memberikan hasil terbaik sesuai keinginan Anda. <br/><br/>
                    </p>
                    <Button className='mb-m-8' label='Mulai Mengisi' variant='primary' onClick={handleClickProceed} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`closer finished ${isShowSectionFinish}`}>
        <div className='img-bg'></div>
        <div className='container'>
          <div className='row'>
            <div className={`col col-d-60 col-m-80 h-center vh-100 section-divider`}>
              <div className='v-center has-cover'>
                <h1 className='a-h1 mb-16 mb-32'>
                  Terima Kasih atas respon Anda!
                </h1>
                <p className='a-p mb-40'>
                  Anda hanya dapat mengisi formulir ini secara satu kali saja. <br/>
                  Coba hubungi pemilik formulir ini jika menurut Anda hal ini adalah kesalahan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={`btn-wrapper dn db-m floating floating-top ${showFloating}`}>
        <div className='progress'></div>
        <div className='row'>
          <div className='col col-d-60 col-m-90 h-center ta-r'>
            <h3 className='title-section'>
              {titleSectionName}
            </h3>
          </div>
        </div>
      </div>

      <section className='questionnaire'>
        <div className="container">
          <div className='row'>
            {/* Slider Section  */}
            <div id="formQuestionaire" className='col col-d-60 col-m-90 mvh-100 h-center'>
              <div className='slider pt-80 pb-120 pb-m-96'>
                {/* slider 1  */}
                <form>
                  <div id="personalInfo" className={`slider-wrapper ${activeIndex === 0 ? 'active': ''}`}>
                    <div className='slider-content'>
                      {renderPersonalInfo()}
                    </div>
                  </div>
                  {/* slider 2  */}
                  <div id="kamarUtama" className={`slider-wrapper ${activeIndex === 1 ? 'active': ''}`}>
                    <div className='slider-content'>
                      <h4 className='a-h2 light mb-64 ta-c'>
                        Sesi ini bertujuan untuk mengetahui {''} {''}
                        <b>
                          ketertarikan dan kebutuhan Anda pada area kamar Utama
                        </b>
                      </h4>
                      {renderKamarUtama()}
                    </div>
                  </div>
                  {/* slider 3  */}
                  <div id="kamarAnak" className={`slider-wrapper ${activeIndex === 2 ? 'active': ''}`}>
                    <div className='slider-content'>
                      <h4 className='a-h2 light mb-64 ta-c'>
                        Sesi ini bertujuan untuk mengetahui {''}
                        <b>
                          ketertarikan dan kebutuhan anggota keluarga anak ke {totalChild}
                        </b>
                      </h4>
                      {totalChild === 1 && renderKamarAnak1()}
                      {totalChild === 2 && renderKamarAnak2()}
                      {totalChild === 3 && renderKamarAnak3()}
                    </div>
                  </div>
                  {/* slider 4  */}
                  {hasLivingRoom && (
                    <div id="kamarTamu" className={`slider-wrapper ${activeIndex === 3 ? 'active': ''}`}>
                      <div className='slider-content'>
                        <h4 className='a-h2 light mb-64 ta-c'>
                          Sesi ini bertujuan untuk mengetahui {''}
                          <b>
                            ketertarikan dan kebutuhan pada kamar tamu
                          </b>
                        </h4>
                        {renderKamarTamu()}
                      </div>
                    </div>
                  )}
                  {/* slider 5  */}
                  {hasParentRoom && (
                    <div id="kamarOrangTua" className={`slider-wrapper ${activeIndex === (hasLivingRoom ? 4 : 3) ? 'active': ''}`}>
                      <div className='slider-content'>
                        <h4 className='a-h2 light mb-64 ta-c'>
                          Sesi ini bertujuan untuk mengetahui {''}
                          <b>
                            ketertarikan dan kebutuhan pada kamar orang tua
                          </b>
                        </h4>
                        {renderKamarOrangTua()}
                      </div>
                    </div>
                  )}
                  {/* slider 6  */}
                  <div id="ruangTamu" className={`slider-wrapper ${activeIndex === (hasParentRoom && hasLivingRoom ? 5 : (!hasParentRoom && hasLivingRoom) || (hasParentRoom && !hasLivingRoom) ? 4 : 3) ? 'active': ''}`}>
                    <div className='slider-content'>
                      <h4 className='a-h2 light mb-64 ta-c'>
                        Sesi ini bertujuan untuk mengetahui {''}
                        <b>
                          ketertarikan dan kebutuhan <br className='dn db-m'  />pada ruang tamu
                        </b>
                      </h4>
                      {renderRuangTamu()}
                    </div>
                  </div>
                  {/* slider 7  */}
                  <div id="foyer" className={`slider-wrapper ${activeIndex === (hasParentRoom && hasLivingRoom ? 6 : (!hasParentRoom && hasLivingRoom) || (hasParentRoom && !hasLivingRoom) ? 5 : 4) ? 'active': ''}`}>
                    <div className='slider-content'>
                      <h4 className='a-h2 light mb-64 ta-c'>
                        Sesi ini bertujuan untuk mengetahui {''}
                        <b>
                          ketertarikan dan kebutuhan pada foyer
                        </b>
                      </h4>
                      {renderFoyer()}
                    </div>
                  </div>
                  {/* slider 8  */}
                  <div id="ruangHobi" className={`slider-wrapper ${activeIndex === (hasParentRoom && hasLivingRoom ? 7 : (!hasParentRoom && hasLivingRoom) || (hasParentRoom && !hasLivingRoom) ? 6 : 5) ? 'active': ''}`}>
                    <div className='slider-content'>
                      <h4 className='a-h2 light mb-64 ta-c'>
                        Sesi ini bertujuan untuk mengetahui {''}
                        <b>
                          ketertarikan dan kebutuhan pada ruang hobi
                        </b>
                      </h4>
                      {renderRuangHobi()}
                    </div>
                  </div>
                  {/* slider 9  */}
                  <div id="ruangMakan" className={`slider-wrapper ${activeIndex === (hasParentRoom && hasLivingRoom ? 8 : (!hasParentRoom && hasLivingRoom) || (hasParentRoom && !hasLivingRoom) ? 7 : 6) ? 'active': ''}`}>
                    <div className='slider-content'>
                      <h4 className='a-h2 light mb-64 ta-c'>
                        Sesi ini bertujuan untuk mengetahui {''}
                        <b>
                          ketertarikan dan kebutuhan pada ruang makan dan dapur
                        </b>
                      </h4>
                      {renderRuangMakan()}
                    </div>
                  </div>
                  {/* slider 10  */}
                  <div id="ruangKerja" className={`slider-wrapper ${activeIndex === (hasParentRoom && hasLivingRoom ? 9 : (!hasParentRoom && hasLivingRoom) || (hasParentRoom && !hasLivingRoom) ? 8 : 7) ? 'active': ''}`}>
                    <div className='slider-content'>
                      <h4 className='a-h2 light mb-64 ta-c'>
                        Sesi ini bertujuan untuk mengetahui {''}
                        <b>
                          ketertarikan dan kebutuhan <br  className='dn db-m' />pada ruang kerja
                        </b>
                      </h4>
                      {renderRuangKerja()}
                    </div>
                  </div>
                  {/* slider 11  */}
                  <div id="ruangBelajar" className={`slider-wrapper ${activeIndex === (hasParentRoom && hasLivingRoom ? 10 : (!hasParentRoom && hasLivingRoom) || (hasParentRoom && !hasLivingRoom) ? 9 : 8) ? 'active': ''}`}>
                    <div className='slider-content'>
                      <h4 className='a-h2 light mb-64 ta-c'>
                        Sesi ini bertujuan untuk mengetahui {''}
                        <b>
                          ketertarikan dan kebutuhan pada ruang belajar
                        </b>
                      </h4>
                      {renderRuangBelajar()}
                    </div>
                  </div>
                  {/* slider 12  */}
                  <div id="other" className={`slider-wrapper ${activeIndex === (hasParentRoom && hasLivingRoom ? 11 : (!hasParentRoom && hasLivingRoom) || (hasParentRoom && !hasLivingRoom) ? 10 : 9) ? 'active': ''}`}>
                    <div className='slider-content'>
                      <h4 className='a-h2 light mb-64 ta-c'>
                        Sesi ini bertujuan untuk mengetahui {''}
                        <b>
                          ketertarikan Anda pada hal lain yang berkaitan dengan penataan interior ruangan atau rumah Anda
                        </b>
                      </h4>
                      {renderOthers()}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showPopup && (
        <Popup>
          <ImageItem setshowPopup={setshowPopup} setisShowFloating={setisShowFloating} imageDetailStyle={imageDetailStyle} setimageDetailStyle={setimageDetailStyle} />
        </Popup>
      )}

      <div className={`btn-wrapper floating ${showFloating}`}>
        <div className='progress'></div>
        <div className='row'>
          <div className='col col-d-60 col-m-90 h-center ta-r'>
            <h3 className='title-section dn-m'>
              {`
                ${titleSectionName}
                ${activeIndex === 2 ? `ke-${totalChild}` : ``}
              `}
            </h3>
            {activeIndex > 0 && (
              <Button className='kembali mr-8 mr-m-0 pl-m-0' label='Kembali' variant='secondary' onClick={handleClickPrev} />
            )}
            <Button disabled={disabledBtnNext} className='' label='Selanjutnya' variant='primary' onClick={handleClickSelanjutnya} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
