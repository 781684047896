import React from 'react';

import './styles.scss';

const Popup = ({ children, onClick }) => {
  return (
    <div className="popup">
      <div className='popup-ovr' onClick={onClick}>
        <div className='popup-content'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
