import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AppRoutes from 'config/routes/';

import SurveyTemplate from 'containers/templates/SurveyTemplate';

import 'assets/styles/styles.scss';

function AppRouter() {
  return (
    <Router>
      <SurveyTemplate>
        <Routes>
          {AppRoutes.map((route) => (
            <Route
              key={`app-route-${route.id}`}
              exact={route.exact}
              path={route.path}
              element={route.element}
            />
          ))}
        </Routes>
      </SurveyTemplate>
    </Router>
  );
}

export default AppRouter;
