import React from 'react';

import {
  Button
} from 'components';

import './styles.scss';

const ImageItem = ({ onClick, setshowPopup, setisShowFloating, imageDetailStyle, setimageDetailStyle }) => {

  const handleHideDetail = () => {
    document.body.style.overflow = 'auto';

    setTimeout(() => {
      setshowPopup(false);
      setisShowFloating(true);
      setimageDetailStyle([]);
    }, 200);
  };

  return (
    <div onclick={onClick} className="img-gal-ovr">
      <div className='img-gal-ovr-btn-wrapper'>
        <Button className='' label='Kembali' variant='black-transparent' onClick={handleHideDetail} withArrowLeft />
      </div>
      <div className='img-gal-ovr-grid'>
        {imageDetailStyle.map((image, index) => (
          <div className="img-gal-ovr-item">
            <img className='' key={`imgdet-style-${index}`} src={image} alt={`detail-style}`} />
          </div>
        ))}
      </div>
      <div className='img-gal-ovr-btn-wrapper mt-24 ta-c'>
        <Button className='' label='Kembali ke Halaman Sebelumnya' variant='black-transparent' onClick={handleHideDetail} withArrowLeft />
      </div>
    </div>
  );
};

export default ImageItem;
