/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';

import {
  InputForm,
  Button,
  RadioButton,
  InputGroup,
  ImageGallery,
  ImageItem,
  Popup
} from 'components';

import { scroller } from 'react-scroll';

import imageUrls from 'assets/images';
import './styles.scss';

const Homepage = () => {

  const [hideLoader, sethideLoader] = useState(false);
  const [isProceed, setisProceed] = useState(false);
  const [isFinished, setisFinished] = useState(false);
  const [isShowFloating, setisShowFloating] = useState(false);
  // const [titleSection, settitleSection] = useState();
  const [titleSectionName, settitleSectionName] = useState();
  const [hasFilled, sethasFilled] = useState(false);
  const [totalChild, settotalChild] = useState(1);

  const [disabledBtnNext, setdisabledBtnNext] = useState(false);

  // popup image gallery
  const [showPopup, setshowPopup] = useState(false);
  const [imageDetailStyle, setimageDetailStyle] = useState([]);

  const isHideLoader = hideLoader? 'hide': '';
  const isHideSectionOpener = isProceed? 'hide': '';
  const isShowSectionFinish = isFinished? 'show': '';
  const showFloating = isShowFloating? 'show': '';

  const [activeIndex, setActiveIndex] = useState(0);

  const totalSlides = 19;

  const [totalInputsPerSlide, setTotalInputsPerSlide] = useState(0);

  const [formData, setFormData] = useState({
    personalInfo: {
      name: '',
      email: '',
      phone: '',
      jumlahAnggota: '',
      jumlahAnak: '',
      memilikiRencana: '',
      biodata: ''
    },
    ambience: '',
    style: '',
    materialBoard: [],
    shape: '',
    type: '',
    kamarUtama: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
      quest5: '',
      quest6: '',
      quest7: '',
    },
    kamarAnak1: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
    },
    kamarAnak2: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
    },
    kamarAnak3: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
    },
    kamarTamu: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
    },
    kamarOrangTua: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
    },
    ruangTamu: {
      quest1: '',
      quest2: '',
    },
    foyer: {
      quest1: '',
      quest2: '',
      quest3: '',
    },
    ruangHobi: {
      quest1: '',
      quest2: '',
    },
    ruangMakan: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
      quest5: '',
      quest6: '',
      quest7: '',
      quest8: '',
      quest9: '',
      quest10: '',
      quest11: '',
    },
    ruangKerja: {
      quest1: '',
    },
    ruangBelajar: {
      quest1: '',
      quest2: '',
    },
    ruangLuar: {
      quest1: '',
      quest2: '',
    },
    other: {
      quest1: '',
      quest2: '',
      quest3: '',
      quest4: '',
      quest5: '',
      quest6: '',
      quest7: '',
      quest8: '',
      quest9: '',
      quest10: '',
      quest11: '',
      quest12: '',
      quest13: '',
      quest14: '',
      quest15: '',
      quest16: '',
      quest17: '',
      quest18: '',
      quest19: '',
      quest20: '',
    },
  });

  const dataSection = [
    "Personal Info Anda",
    "Ambience",
    "Style",
    "Material Board",
    "Shape",
    "Type",
    "Kamar Utama",
    "Kamar Anak",
    "Kamar Tamu",
    "Ruang Orang Tua",
    "Ruang Tamu",
    "Foyer",
    "Ruang Hobi",
    "Ruang Makan dan Dapur",
    "Ruang Kerja",
    "Ruang Belajar",
    "Ruang Luar atau Taman",
    "Pertanyaan Lainnya",
  ];

  const handleClickProceed = () => {
    setisProceed(true);
    document.body.style.overflow = 'auto'; // or 'scroll'

    setActiveIndex(0);
    // settitleSection(`Bagian 1: `);
    settitleSectionName(`${dataSection[0]}`);
    setdisabledBtnNext(true);

    setTimeout(() => {
      setisShowFloating(true);
      updateTotalInputsPerSlide();
    }, 1000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Split the name into an array to access the nested structure
    const nameParts = name.split('.');

    if (nameParts[1] === undefined) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      // Use the nameParts to update the formData
      setFormData((prevData) => ({
        ...prevData,
        [nameParts[0]]: {
          ...prevData[nameParts[0]],
          [nameParts[1]]: value,
        },
      }));
    }
  };

  const handleBlur = (e) => {
    console.log(
      // '@@validateInput', validateInputs(activeIndex)
    );
  };

  const updateTotalInputsPerSlide = () => {
    const sliderWrapper = document.querySelector('.slider-wrapper.active');
    if (sliderWrapper) {
      const inputs = sliderWrapper.getElementsByTagName('input');
      const uniqueInputNames = new Set();

      for (const input of inputs) {
        uniqueInputNames.add(input.name);
      }

      setTotalInputsPerSlide(uniqueInputNames.size + 1);
      console.log("uniqueInputNames.size", uniqueInputNames, uniqueInputNames.size);
    }
  };

  const handleClickPrev = () => {
    scroller.scrollTo('formQuestionaire', {
      duration: 500, // Duration of the scroll animation in milliseconds
      smooth: 'easeInOutQuart', // Easing function for smooth scrolling
    });

    if (activeIndex === 7) {
      if (totalChild === 1) {
        setActiveIndex((prevIndex) =>  Math.max(prevIndex - 1, 0));
        settitleSectionName(`${dataSection[activeIndex - 1]}`);
        setTimeout(() => {
          updateTotalInputsPerSlide();
        }, 500);
      } else {
        settotalChild(totalChild - 1);
      }
    } else {
      setActiveIndex((prevIndex) =>  Math.max(prevIndex - 1, 0));
      settitleSectionName(`${dataSection[activeIndex - 1]}`);
      setTimeout(() => {
        updateTotalInputsPerSlide();
      }, 500);
    }

    setdisabledBtnNext(false);
  };

  const handleClickSelanjutnya = () => {
    // Validate the inputs before moving to the next slide
    scroller.scrollTo('formQuestionaire', {
      duration: 500, // Duration of the scroll animation in milliseconds
      smooth: 'easeInOutQuart', // Easing function for smooth scrolling
    });

    console.log('@@handleClickSelanjutnya');

    if (activeIndex === 7) {
      if (totalChild >= formData.personalInfo.jumlahAnak) {
        setActiveIndex((prevIndex) => Math.min(prevIndex + 1, totalSlides - 1));
        settitleSectionName(`${dataSection[activeIndex + 1]}`);
        setdisabledBtnNext(true);
      } else {
        settotalChild(totalChild + 1);
      }
    } else if (activeIndex === 17) {
      setisShowFloating(false);

      sessionStorage.setItem('data', formData);

      scroller.scrollTo('formQuestionaire', {
        duration: 500, // Duration of the scroll animation in milliseconds
        smooth: 'easeInOutQuart', // Easing function for smooth scrolling
      });

      setTimeout(() => {
        setisFinished(true);
        localStorage.setItem('finished', true);
        document.body.style.overflow = 'hidden';
      }, 600);

    } else {
      if (validateInputs(activeIndex)) {
        setActiveIndex((prevIndex) => Math.min(prevIndex + 1, totalSlides - 1));
        settitleSectionName(`${dataSection[activeIndex + 1]}`);
        setdisabledBtnNext(true);
      } else {
        alert('Data yang diperlukan belum diisi. Harap isi seluruh data untuk dapat melanjutkan');
        document.body.style.overflow = 'auto'; // or 'scroll'
      }
    }


    // // validation finish
    // if (activeIndex === 17) {
    //   setisShowFloating(false);

    //   sessionStorage.setItem('data', formData);

    //   scroller.scrollTo('formQuestionaire', {
    //     duration: 500, // Duration of the scroll animation in milliseconds
    //     smooth: 'easeInOutQuart', // Easing function for smooth scrolling
    //   });

    //   setTimeout(() => {
    //     setisFinished(true);
    //     localStorage.setItem('finished', true);
    //     document.body.style.overflow = 'hidden';
    //   }, 600);

    // }
  };

  const validateInputs = (index) => {
    // Get the slider-wrapper element corresponding to the active index
    const sliderWrapper = document.getElementsByClassName('slider-wrapper')[index];
    const sliderId = sliderWrapper.id;
    let isFilled = false;

    if (index === 0 || index > 5) {
      isFilled = Object.values(formData[sliderId]).every(value => value !== '');
    } else if (index === 1 || index === 2 || index === 4 || index === 5) {
      isFilled = formData[sliderId] !== '';
    } else {
      isFilled = formData[sliderId].length;
    }

    if (isFilled) {
      // All children keys of "personalInfo" are non-empty
      return true;
    } else {
      // Not all children keys of "personalInfo" are non-empty
      return false;
    }
  };

  const compileFormDataToJSON = () => {
    // Combine the form data from all input fields into a JSON object
    return {
      ...formData,
    };
  };

  const renderPersonalInfo = () => {
    return (
      <>
        <InputForm type='text' variant='text-top' label='Nama Lengkap' name='personalInfo.name' value={formData.personalInfo.name} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm type='email' variant='text-top' label='Alamat Email' name='personalInfo.email' value={formData.personalInfo.email} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm type='text' variant='text-top' label='Nomor Telepon' name='personalInfo.phone' value={formData.personalInfo.phone} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm
          type='number'
          variant='text-top'
          label='Berapa Jumlah Anggota Keluarga Anda Saat ini?'
          labelError='Jumlah Anggota'
          name='personalInfo.jumlahAnggota'
          value={formData.personalInfo.jumlahAnggota}
          onChange={handleChange} onBlur={handleBlur}
          mandatory
        />
        <InputForm
          type='number'
          maxLength={1}
          variant='text-top'
          label='Berapa Jumlah Anak Anda Saat ini?'
          labelError='Jumlah Anak'
          name='personalInfo.jumlahAnak'
          value={formData.personalInfo.jumlahAnak}
          onChange={handleChange} onBlur={handleBlur}
          mandatory
        />
        <InputGroup className='mb-40 mb-m-16' label="Apakah Anda memiliki rencana untuk menambah anggota keluarga?">
          <Fragment>
            <RadioButton className='dib mr-24' name="personalInfo.memilikiRencana" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="personalInfo.memilikiRencana" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="personalInfo.memilikiRencana" value={`Lainnya: ${formData.personalInfo.memilikiRencana}`} onChange={handleChange} onBlur={handleBlur} label="Lainnya" defaultSelected={false} withText={true} />
          </Fragment>
        </InputGroup>
        <InputForm
          type='text'
          variant='text-top'
          label='Tuliskan bidodata singkat mengenai (umur,hobby,koleksi,minat,dan warna ) kesukaan dari anggota keluarga Anda, yaitu Ayah,ibu,anak 1, anak 2, anak 3, dan orang tua.'
          labelError='Biodata'
          name='personalInfo.biodata'
          value={formData.personalInfo.biodata}
          onChange={handleChange} onBlur={handleBlur}
          mandatory
        />
      </>
    )
  };
  const renderAmbience = () => {
    return (
      <>
        <ImageGallery key="ig-1" setFormData={setFormData} formData={formData} style={formData.ambience} value="ambience-bright" name="ambience" desc={"Bright"} thumb={imageUrls.ambienceBright} images={imageUrls.ambienceBrightList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-2" setFormData={setFormData} formData={formData} style={formData.ambience} value="ambience-dark" name="ambience" desc={"Dark"} thumb={imageUrls.ambienceDark} images={imageUrls.ambienceDarkList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-3" setFormData={setFormData} formData={formData} style={formData.ambience} value="ambience-warm" name="ambience" desc={"Warm"} thumb={imageUrls.ambienceWarm} images={imageUrls.ambienceWarmList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
      </>
    );
  };
  const renderStyle = () => {
    return (
      <>
        <ImageGallery key="ig-4" setFormData={setFormData} formData={formData} style={formData.style} value="bohemian" name="style" desc={"Bohemian"} thumb={imageUrls.bohemian} images={imageUrls.bohemianList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-5" setFormData={setFormData} formData={formData} style={formData.style} value="dark-interior" name="style" desc={"Dark Interior"} thumb={imageUrls.darkInterior} images={imageUrls.darkInteriorList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-6" setFormData={setFormData} formData={formData} style={formData.style} value="farmhouse" name="style" desc={"Farmhouse"} thumb={imageUrls.farmhouse} images={imageUrls.farmhouseList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-7" setFormData={setFormData} formData={formData} style={formData.style} value="modern-classic" name="style" desc={"Klasik Modern"} thumb={imageUrls.klasikModern} images={imageUrls.klasikModernList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-8" setFormData={setFormData} formData={formData} style={formData.style} value="modern-elegan" name="style" desc={"Modern Elegan"} thumb={imageUrls.modernElegan} images={imageUrls.modernEleganList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-9" setFormData={setFormData} formData={formData} style={formData.style} value="modern-industrial" name="style" desc={"Modern Industrial"} thumb={imageUrls.modernIndustrial} images={imageUrls.modernIndustrialList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-10" setFormData={setFormData} formData={formData} style={formData.style} value="modern-japandi" name="style" desc={"Modern Japandi"} thumb={imageUrls.modernJapandi} images={imageUrls.modernJapandiList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-11" setFormData={setFormData} formData={formData} style={formData.style} value="modern-contemporary" name="style" desc={"Modern Kontemporer"} thumb={imageUrls.modernKontemporer} images={imageUrls.modernKontemporerList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-12" setFormData={setFormData} formData={formData} style={formData.style} value="modern-luxury" name="style" desc={"Modern Luxury"} thumb={imageUrls.modernLuxury} images={imageUrls.modernLuxuryList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-13" setFormData={setFormData} formData={formData} style={formData.style} value="modern-nature" name="style" desc={"Modern Natural"} thumb={imageUrls.modernNature} images={imageUrls.modernNatureList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-14" setFormData={setFormData} formData={formData} style={formData.style} value="modern-oriental" name="style" desc={"Modern Oriental"} thumb={imageUrls.modernOriental} images={imageUrls.modernOrientalList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-15" setFormData={setFormData} formData={formData} style={formData.style} value="pop-art" name= "style" desc={"Pop Art"} thumb={imageUrls.popArt} images={imageUrls.popArtList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
      </>
    );
  };
  const renderMaterialBoard = () => {
    return imageUrls.materialBoardList.map((v, index) => (
      <ImageGallery key={`ig-16-${index}`} multiple setFormData={setFormData} formData={formData} style={formData.materialBoard} value={`mb-${index + 1}`} name="materialBoard" thumb={v} />
    ));
  };
  const renderShape = () => {
    return (
      <>
        <ImageGallery key="ig-17" setFormData={setFormData} formData={formData} style={formData.shape} value="curve" name="shape" desc={"Curve"} thumb={imageUrls.shapeCurve} images={imageUrls.shapeCurveList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-18" setFormData={setFormData} formData={formData} style={formData.shape} value="square" name="shape" desc={"Square"} thumb={imageUrls.shapeSquare} images={imageUrls.shapeSquareList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-19" setFormData={setFormData} formData={formData} style={formData.shape} value="triangle" name="shape" desc={"Triangle"} thumb={imageUrls.shapeTriangle} images={imageUrls.shapeTriangleList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
      </>
    );
  };
  const renderType = () => {
    return (
      <>
        <ImageGallery key="ig-20" setFormData={setFormData} formData={formData} style={formData.type} value="curve" name="type" desc={"Simple"} thumb={imageUrls.simple} images={imageUrls.simpleList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
        <ImageGallery key="ig-21" setFormData={setFormData} formData={formData} style={formData.type} value="triangle" name="type" desc={"Detail"} thumb={imageUrls.detail} images={imageUrls.detailList} setisShowFloating={setisShowFloating} setimageDetailStyle={setimageDetailStyle} setshowPopup={setshowPopup} />
      </>
    );
  };
  const renderKamarUtama = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Deskripsikan style yang Anda inginkan untuk Kamar Utama' name='kamarUtama.quest1' value={formData.kamarUtama.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja kerja,cermin rias, sofa, kursi, dan TV pada area kamar tidur? *Contoh: saya hanya memerlukan meja kerja dan TV' name='kamarUtama.quest2' value={formData.kamarUtama.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu' name='kamarUtama.quest3' value={formData.kamarUtama.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apa warna yang anda sukai dan tidak sukai pada kamar?' name='kamarUtama.quest4' value={formData.kamarUtama.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Manakah yang lebih Anda sukai?">
          <Fragment>
            <RadioButton className='dib mr-24' name="kamarUtama.quest5" value="1" label="Meja rias berada di kamar utama" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <br/>
            <RadioButton className='dib mr-24' name="kamarUtama.quest5" value="2" label="Meja rias berada pada Walk In Closet" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Manakah yang lebih Anda sukai?">
          <Fragment>
            <RadioButton className='dib mr-24' name="kamarUtama.quest6" value="1" label="Meja kerja berada di kamar utama" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <br/>
            <RadioButton className='dib mr-24' name="kamarUtama.quest6" value="2" label="Meja kerja memiliki ruangan tersendiri" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah Anda menginginkan area untuk menaruh brankas?">
          <Fragment>
            <RadioButton className='dib mr-24' name="kamarUtama.quest7" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="kamarUtama.quest7" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
      </>
    );
  };
  const renderKamarAnak1 = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar anak ke 1?' name='kamarAnak1.quest1' value={formData.kamarAnak1.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar anak 1?' name='kamarAnak1.quest2' value={formData.kamarAnak1.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarAnak1.quest3' value={formData.kamarAnak1.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarAnak1.quest4' value={formData.kamarAnak1.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderKamarAnak2 = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar anak ke 2?' name='kamarAnak2.quest1' value={formData.kamarAnak2.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar anak 2?' name='kamarAnak2.quest2' value={formData.kamarAnak2.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarAnak2.quest3' value={formData.kamarAnak2.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarAnak2.quest4' value={formData.kamarAnak2.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderKamarAnak3 = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar anak ke 3?' name='kamarAnak3.quest1' value={formData.kamarAnak3.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar anak 3?' name='kamarAnak3.quest2' value={formData.kamarAnak3.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarAnak3.quest3' value={formData.kamarAnak3.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarAnak3.quest4' value={formData.kamarAnak3.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderKamarTamu = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar tamu?' name='kamarTamu.quest1' value={formData.kamarTamu.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar tamu?' name='kamarTamu.quest2' value={formData.kamarTamu.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarTamu.quest3' value={formData.kamarTamu.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarTamu.quest4' value={formData.kamarTamu.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderKamarOrangTua = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan dan jelaskan style apa yang Anda inginkan pada kamar orang tua?' name='kamarOrangTua.quest1' value={formData.kamarOrangTua.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan meja belajar, meja rias, lemari buku, dan Tv pada kamar orang tua?' name='kamarOrangTua.quest2' value={formData.kamarOrangTua.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai lemari tertutup atau terbuka?. Dan menggunakan kaca pintu solid atau pintu transparan? *Contoh: Saya menyukai lemari tertutup dengan pintu transparan.' name='kamarOrangTua.quest3' value={formData.kamarOrangTua.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna apa yang Anda sukai dan tidak sukai pada kamar?' name='kamarOrangTua.quest4' value={formData.kamarOrangTua.quest4} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderRuangTamu = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apa saja yang Anda butuhkan selain sofa dan TV kabinet? Apakah Anda memerlukan sofa L Shape atau tidak? Dan sebutkan ukuruan TV yang Anda butuhkan' name='ruangTamu.quest1' value={formData.ruangTamu.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Aktivitas apa saja yang biasanya Anda lakukan di ruang tamu? dan sebutkan kegiatannya. Misal: Meminum teh, relaksasi, atau bekerja.' name='ruangTamu.quest2' value={formData.ruangTamu.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderFoyer = () => {
    return (
      <>
        <InputGroup className='mb-40' label="Manakah yang lebih Anda sukai untuk area foyer?">
          <Fragment>
            <RadioButton className='dib mr-24' name="foyer.quest1" value="1" label="Konsep Open Space" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="foyer.quest1" value="2" label="Konsep Tertutup" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah pada area foyer Anda memerlukan tempat duduk? ">
          <Fragment>
            <RadioButton className='dib mr-24' name="foyer.quest2" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="foyer.quest2" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah Anda membutuhkan lemari sepatu?">
          <Fragment>
            <RadioButton className='dib mr-24' name="foyer.quest3" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="foyer.quest3" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
      </>
    );
  };
  const renderRuangHobi = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apabila terdapat ruang hobi, apa saja aktivitas dan kebutuhan Anda pada ruang hobi?' name='ruangHobi.quest1' value={formData.ruangHobi.quest1} onChange={handleChange} onBlur={handleBlur} mandatory /><br/>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apa saja perabotan dan elektronik yang Anda butuhkan pada ruang hobi?' name='ruangHobi.quest2' value={formData.ruangHobi.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderRuangMakan = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Meja makan untuk berapa orang yang Anda butuhkan?' name='ruangMakan.quest1' value={formData.ruangMakan.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Manakah dibawah ini yang lebih Anda sukai?">
          <Fragment>
            <RadioButton className='dib mr-24' name="ruangMakan.quest2" value="1" label="Meja makan berbentuk oval " onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="ruangMakan.quest2" value="2" label="Meja makan berbentuk bulat" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="ruangMakan.quest2" value="3" label="Meja makan berbentuk kotak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Manakah finishing meja makan yang lebih Anda sukai untuk top table?">
          <Fragment>
            <RadioButton className='dib mr-24' name="ruangMakan.quest3" value="1" label="Marmer" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="ruangMakan.quest3" value="2" label="Kaca" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="ruangMakan.quest3" value="3" label="Kayu solid" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah Anda memerlukan tempat untuk penyimpanan piring, gelas, sendok di area makan? Serta dispenser, kulkas  di sekitar meja makan?">
          <Fragment>
            <RadioButton className='dib mr-24' name="ruangMakan.quest4" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="ruangMakan.quest4" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda akan melakukan aktivitas di dapur, selain memasak makanan?' name='ruangMakan.quest5' value={formData.ruangMakan.quest5} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apa saja yang Anda butuhkan di dapur? Misalkan: kulkas 2 pintu , microwave, oven, dsb.' name='ruangMakan.quest6' value={formData.ruangMakan.quest6} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menyukai island cabinet? Jika iya, apakah island cabinet akan dipergunakan untuk kompor atau sink atau polos saja?' name='ruangMakan.quest7' value={formData.ruangMakan.quest7} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Tinggi island yang anda inginkan, apakah setinggi meja makan standar atau meja bar?' name='ruangMakan.quest8' value={formData.ruangMakan.quest8} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Island cabinet di pergunakan untuk makan atau hanya untuk kegiatan memasak saja?' name='ruangMakan.quest9' value={formData.ruangMakan.quest9} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda mempunyai dapur kotor? Jika iya, sebutkan Peralatan elektronik apa saja yang yang ada di dapur kotor!' name='ruangMakan.quest10' value={formData.ruangMakan.quest10} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan area Bar? Jika iya, kebutuhannya apa saja yang Anda perlukan? Misal: Wine Chiller , coffee maker, tempat menaruh gelas dan botol wine, dsb.' name='ruangMakan.quest11' value={formData.ruangMakan.quest11} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderRuangKerja = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan ruang kerja khusus?Jika iya, apakah ruangan kerja Anda ingin terlihat formal atau santai? Dan sebutkan kebutuhan apa saja yang Anda perlukan. Misal: TV, Kulkas, Dispenser, Sofa , brankas,dsb.' name='ruangKerja.quest1' value={formData.ruangKerja.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderRuangBelajar = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan ruang belajar khusus untuk anak atau les privat? Jika iya, sebutkan kebutuhan apa saja yang Anda perlukan!' name='ruangBelajar.quest1' value={formData.ruangBelajar.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memerlukan lemari buku? Jika Iya, mana yang lebih Anda sukai, menggunakan pintu terbuka atau tertutup?' name='ruangBelajar.quest2' value={formData.ruangBelajar.quest2} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };
  const renderRuangLuar = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda membutuhkan ruang luar? Jika Iya, kebutuhan apa saja yang Anda butuhkan? Misal: tempat duduk, alat BBQ, dsb.' name='ruangLuar.quest1' value={formData.ruangLuar.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Manakah yang Anda inginkan?">
          <Fragment>
            <RadioButton className='dib mr-24' name="ruangLuar.quest2" value="1" label="Tanaman Menggunakan Pot" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="ruangLuar.quest2" value="2" label="Tanaman Menggunakan Planter Box" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
      </>
    );
  };
  const renderOthers = () => {
    return (
      <>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menerapkan ilmu Fengshui? Jika iya, apa saja yang diterapkan?' name='other.quest1' value={formData.other.quest1} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Apakah Anda suka dengan permainan cermin?">
          <Fragment>
            <RadioButton className='dib mr-24' name="other.quest2" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="other.quest2" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputForm variant="text-top" type='text' labelError='ini' label='Ruang mana yang menjadi prioritas Anda untuk berkumpul bersama keluarga? Dan sebutkan kebutuhan yang Anda perlukan!' name='other.quest3' value={formData.other.quest3} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Apakah Anda suka pada permainan panel dinding?">
          <Fragment>
            <RadioButton className='dib mr-24' name="other.quest4" value="1" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="other.quest4" value="2" label="Saya lebih menyukai menggunakan cat" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="other.quest4" value="3" label="Saya lebih menyukai menggunakan wallpaper" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="other.quest4" value="4" label="Saya lebih menyukai menggunakan cat texture" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Manakah yang lebih Anda sukai, sebagai bahan pelapis sofa dan kepala ranjang?">
          <Fragment>
            <RadioButton className='dib mr-24' name="other.quest5" value="1" label="Kulit" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} /><br/>
            <RadioButton className='dib mr-24' name="other.quest5" value="2" label="Fabric" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah Anda menyukai permainan drop ceiling?">
          <Fragment>
            <RadioButton className='dib mr-24' name="other.quest6" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="other.quest6" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah kami boleh merubah lantai existing?">
          <Fragment>
            <RadioButton className='dib mr-24' name="other.quest7" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="other.quest7" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah kami boleh merubah dinding?">
          <Fragment>
            <RadioButton className='dib mr-24' name="other.quest8" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="other.quest8" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputGroup className='mb-40' label="Apakah kami perlu merubah kamar mandi existing?">
          <Fragment>
            <RadioButton className='dib mr-24' name="other.quest9" value="Ya" label="Iya" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="other.quest9" value="Tidak" label="Tidak" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan bahan yang Anda inginkan dalam membentuk railing tangga! Dengan konsep terbuka atau tertutup?' name='other.quest10' value={formData.other.quest10} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan permasalahan apa saja yang terjadi pada rumah sebelumnya? Sertakan dengan hal-hal yang ingin Anda ingin dihindari pada rumah baru.' name='other.quest11' value={formData.other.quest11} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda mempunyai elemen di rumah lama yang ingin tetap di adakan? Misal: piano, barang pajangan,dsb.' name='other.quest12' value={formData.other.quest12} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Sebutkan elemen yang ingin Anda utamakan pada rumah ini? Misal: Kayu,air,dan batu' name='other.quest13' value={formData.other.quest13} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Warna yang Anda inginkan untuk mendominasi rumah?' name='other.quest14' value={formData.other.quest14} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda menginginkan loose furniture yang dibuat khusus, atau beli jadi?Jika beli, apa saja yang ingin Anda beli dan sebutkan merek apa saja yang Anda ketahui?' name='other.quest15' value={formData.other.quest15} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Dimanakah Anda akan menaruh peralatan untuk bersih-bersih rumah? Apakah di setiap lantai, atau diletakkan di ruangan service?Dan sebutkan letak posisi dari Arsiteknya.' name='other.quest16' value={formData.other.quest16} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputGroup className='mb-40' label="Sejauh mana Anda ingin terlibat dalam proses desain?">
          <Fragment>
            <RadioButton className='dib mr-24' name="other.quest17" value="1" label="Saya lebih suka memberikan masukan sepanjang proses design" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
            <RadioButton className='dib mr-24' name="other.quest17" value="2" label="Saya lebih suka memberikan kebebasan penuh kepada designer" onChange={handleChange} onBlur={handleBlur} defaultSelected={false} />
          </Fragment>
        </InputGroup>
        <InputForm variant="text-top" type='text' labelError='ini' label='Apakah Anda memiliki batasan waktu yang perlu diperhatikan untuk menyelesaikan proyek desain ini?' name='other.quest18' value={formData.other.quest18} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Deskripsikan rumah impian yang Anda inginkan!' name='other.quest19' value={formData.other.quest19} onChange={handleChange} onBlur={handleBlur} mandatory />
        <InputForm variant="text-top" type='text' labelError='ini' label='Tuliskan tiga kata yang ingin Anda gambarkan ketika orang masuk ke dalam rumah Anda !' name='other.quest20' value={formData.other.quest20} onChange={handleChange} onBlur={handleBlur} mandatory />
      </>
    );
  };

  // useEffect
  useEffect(() => {
    const isFinished = localStorage.getItem('finished');

    if (isFinished === 'true') {
      sethasFilled(isFinished);
    } else {
      sethasFilled(false);
    }

    setTimeout(() => {
      sethideLoader(true);
      // document.body.style.overflow = 'hidden';
    }, 3500);
  }, []);

  // useEffect(() => {
  //   handleCheckNextBtn();
  // }, [handleClickSelanjutnya]);

  useEffect(() => {
    if (activeIndex !== 7) {
      setTimeout(() => {
        if (validateInputs(activeIndex)) {
          setdisabledBtnNext(!validateInputs(activeIndex));
        } else {
          setdisabledBtnNext(true);
        }
      }, 200);
    }
  }, [handleChange, handleClickSelanjutnya]);

  useEffect(() => {
    if (activeIndex === 7) {
      setTimeout(() => {
        const isKamarAnakValid = Object.values(formData[`kamarAnak${totalChild}`]).every(value => value !== '');

        if (isKamarAnakValid) {
          // All keys in kamarAnak${totalChild} have values
          console.log(`@@kamarAnak${totalChild} is valid`);
          setdisabledBtnNext(!isKamarAnakValid);
        } else {
          // Some keys in kamarAnak${totalChild} are missing values
          console.log(`@@kamarAnak${totalChild} is not valid`, formData[`kamarAnak${totalChild}`]);
          setdisabledBtnNext(true);
        }
      }, 200);
    }
  }, [handleChange]);


  return (
    <>
      {/* for every transition page  */}
      <section className={`loader ${isHideLoader}`}>
        <div className='container'>
          <img src={imageUrls.gifLoaderLogo} alt='Logo' />
        </div>
      </section>

      <section className={`opener ${isHideSectionOpener}`}>
        <div className='img-bg'></div>
        <div className='container'>
          <div className='row'>
            <div className={`col col-d-60 col-m-80 h-center vh-100 section-divider`}>
              <div className='v-center has-cover ta-l ta-m-c'>
                {hasFilled? (
                  <>
                    <h1 className='a-h1 mb-16 mb-32'>
                      Anda sudah mengisi kuisioner ini.
                    </h1>
                    <p className='a-p mb-40'>
                      Anda hanya dapat mengisi formulir ini secara satu kali saja. <br/>
                      Coba hubungi pemilik formulir ini jika menurut Anda hal ini adalah kesalahan.
                    </p>
                  </>
                ): (
                  <>
                    <h1 className='a-h1 mb-32 ta-l light'>
                      Dear, <br/>
                      <b>
                        Kustomer Manna Interior
                      </b>
                    </h1>
                    <p className='a-p mb-40 mb-m-16 ta-l'>
                      Mohon jawab semua pertanyaan, sesuai dengan apa yang Anda sukai dan butuhkan. <br/>Agar kami dapat memberikan hasil terbaik sesuai keinginan Anda. <br/><br/>
                    </p>
                    <Button className='mb-m-8' label='Mulai Mengisi' variant='primary' onClick={handleClickProceed} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`closer finished ${isShowSectionFinish}`}>
        <div className='img-bg'></div>
        <div className='container'>
          <div className='row'>
            <div className={`col col-d-60 col-m-80 h-center vh-100 section-divider`}>
              <div className='v-center has-cover'>
                <h1 className='a-h1 mb-16 mb-32'>
                  Terima Kasih atas respon Anda!
                </h1>
                <p className='a-p mb-40'>
                  Anda hanya dapat mengisi formulir ini secara satu kali saja. <br/>
                  Coba hubungi pemilik formulir ini jika menurut Anda hal ini adalah kesalahan.
                </p>
                {/* <Button className='' label='GET DATA' variant='primary' onClick={() => console.log(compileFormDataToJSON())} /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={`btn-wrapper dn db-m floating floating-top ${showFloating}`}>
        <div className='progress'></div>
        <div className='row'>
          <div className='col col-d-60 col-m-90 h-center ta-r'>
            <h3 className='title-section'>
              {titleSectionName}
            </h3>
          </div>
        </div>
      </div>

      <section className='questionnaire'>
        <div className="container">
          <div className='row'>
            {/* Slider Section  */}
            <div id="formQuestionaire" className='col col-d-60 col-m-90 mvh-100 h-center'>
              <div className='slider pt-80 pb-120 pb-m-96'>
                {/* slider 1  */}
                <div id="personalInfo" className={`slider-wrapper ${activeIndex === 0? 'active': ''}`}>
                  <div className='slider-content'>
                    {renderPersonalInfo()}
                  </div>
                </div>
                {/* slider 2  */}
                <div id="ambience" className={`slider-wrapper ${activeIndex === 1? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Silahkan pilih <b>design ambience</b> yang ada dalam pilihan di bawah ini:
                    </h4>
                    {renderAmbience()}
                  </div>
                </div>
                {/* slider 3  */}
                <div id="style" className={`slider-wrapper ${activeIndex === 2? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Silahkan pilih <b>design style</b> yang ada dalam pilihan di bawah ini:
                    </h4>
                    {renderStyle()}
                  </div>
                </div>
                {/* slider 4  */}
                <div id="materialBoard" className={`slider-wrapper ${activeIndex === 3? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Pilih <b>material board</b> yang tersedia.
                      <br/>
                      <div className='dn db-m'>
                        Material dapat dipilih <b>lebih dari satu.</b>
                      </div>.
                      <div className='db dn-m'>
                        Material dapat dipilih <b>lebih dari satu item</b>
                      </div>.
                    </h4>
                    {renderMaterialBoard()}
                  </div>
                </div>
                {/* slider 5  */}
                <div id="shape" className={`slider-wrapper ${activeIndex === 4? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Pilih <b>shape</b> yang diinginkan:
                    </h4>
                    {renderShape()}
                  </div>
                </div>
                {/* slider 6  */}
                <div id="type" className={`slider-wrapper ${activeIndex === 5? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-24 ta-c'>
                      Pilih <b>design type</b> yang diinginkan:
                    </h4>
                    {renderType()}
                  </div>
                </div>
                {/* slider 7  */}
                <div id="kamarUtama" className={`slider-wrapper ${activeIndex === 6? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''} {''}
                      <b>
                        ketertarikan dan kebutuhan Anda pada area kamar Utama
                      </b>
                    </h4>
                    {renderKamarUtama()}
                  </div>
                </div>
                {/* slider 8  */}
                <div id="kamarAnak" className={`slider-wrapper ${activeIndex === 7? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan anggota keluarga anak ke {totalChild}
                      </b>
                    </h4>
                    {totalChild === 1 && renderKamarAnak1()}
                    {totalChild === 2 && renderKamarAnak2()}
                    {totalChild === 3 && renderKamarAnak3()}
                  </div>
                </div>
                {/* slider 11  */}
                <div id="kamarTamu" className={`slider-wrapper ${activeIndex === 8? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan pada kamar tamu
                      </b>
                    </h4>
                    {renderKamarTamu()}
                  </div>
                </div>
                {/* slider 11 new  */}
                <div id="kamarOrangTua" className={`slider-wrapper ${activeIndex === 9? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan pada kamar orang tua
                      </b>
                    </h4>
                    {renderKamarOrangTua()}
                  </div>
                </div>
                {/* slider 12  */}
                <div id="ruangTamu" className={`slider-wrapper ${activeIndex === 10? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan <br className='dn db-m'  />pada ruang tamu
                      </b>
                    </h4>
                    {renderRuangTamu()}
                  </div>
                </div>
                {/* slider 13  */}
                <div id="foyer" className={`slider-wrapper ${activeIndex === 11? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan pada foyer
                      </b>
                    </h4>
                    {renderFoyer()}
                  </div>
                </div>
                {/* slider 14  */}
                <div id="ruangHobi" className={`slider-wrapper ${activeIndex === 12? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan pada ruang hobi
                      </b>
                    </h4>
                    {renderRuangHobi()}
                  </div>
                </div>
                {/* slider 15  */}
                <div id="ruangMakan" className={`slider-wrapper ${activeIndex === 13? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan pada ruang makan dan dapur
                      </b>
                    </h4>
                    {renderRuangMakan()}
                  </div>
                </div>
                {/* slider 16  */}
                <div id="ruangKerja" className={`slider-wrapper ${activeIndex === 14? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan <br  className='dn db-m' />pada ruang kerja
                      </b>
                    </h4>
                    {renderRuangKerja()}
                  </div>
                </div>
                {/* slider 17  */}
                <div id="ruangBelajar" className={`slider-wrapper ${activeIndex === 15? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan pada ruang belajar
                      </b>
                    </h4>
                    {renderRuangBelajar()}
                  </div>
                </div>
                {/* slider 18  */}
                <div id="ruangLuar" className={`slider-wrapper ${activeIndex === 16? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan dan kebutuhan Anda pada ruang luar atau taman 
                      </b>
                    </h4>
                    {renderRuangLuar()}
                  </div>
                </div>
                {/* slider 19  */}
                <div id="other" className={`slider-wrapper ${activeIndex === 17? 'active': ''}`}>
                  <div className='slider-content'>
                    <h4 className='a-h2 light mb-64 ta-c'>
                      Sesi ini bertujuan untuk mengetahui {''}
                      <b>
                        ketertarikan Anda pada hal lain yang berkaitan dengan penataan interior ruangan atau rumah Anda
                      </b>
                    </h4>
                    {renderOthers()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showPopup && (
        <Popup>
          <ImageItem setshowPopup={setshowPopup} setisShowFloating={setisShowFloating} imageDetailStyle={imageDetailStyle} setimageDetailStyle={setimageDetailStyle} />
        </Popup>
      )}

      <div className={`btn-wrapper floating ${showFloating}`}>
        <div className='progress'></div>
        <div className='row'>
          <div className='col col-d-60 col-m-90 h-center ta-r'>
            <h3 className='title-section dn-m'>
              {`
                ${titleSectionName}
                ${activeIndex === 7 ? `ke-${totalChild}` : ``}
              `}
            </h3>
            {activeIndex > 0 && (
              <Button className='kembali mr-8 mr-m-0 pl-m-0' label='Kembali' variant='secondary' onClick={handleClickPrev} />
            )}
            <Button disabled={disabledBtnNext} className='' label='Selanjutnya' variant='primary' onClick={handleClickSelanjutnya} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
