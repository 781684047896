/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './styles.scss';

const RadioButton = ({ className, label, name, value, defaultSelected, withText, onChange, checked }) => {
  // const [isSelected, setIsSelected] = useState(defaultSelected);
  const [valueState, setvalueState] = useState('');

  const handleRadioChange = (e) => {
    // setIsSelected(!isSelected);
    setvalueState(e.target.value);

    // console.log('@@handleRadioChange', e.target.value);

    onChange(e);
  };

  const handleRadioTextChange = (e) => {
    setvalueState(e.target.value);

    onChange(e);
  };

  return (
    <label className={`radio-button ${className}`}>
      <input
        className='radio-button__radio'
        type="radio"
        value={withText ? valueState : value}
        name={name}
        checked={checked}
        onChange={handleRadioChange}
      />
      {withText && checked && (
        <div className={`radio-button__input-txt-wrapper focus`}>
          <input
            className='radio-button__input-txt'
            type="text"
            value={valueState}
            name={name}
            onChange={handleRadioTextChange}
          />
        </div>
      )}
      {label}
    </label>
  );
}

export default RadioButton;
