import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const InputForm = ({
  className,
  type,
  label,
  labelError,
  name,
  min,
  max,
  value,
  onChange,
  onBlur,
  variant,
  mandatory,
  maxLength,
  placeholder
}) => {

  const inputRef = useRef(null);

  // useStates
  const [focus, setfocus] = useState(false);
  const [showErrorMessage, setshowErrorMessage] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');

  // validator classes
  const isShowErrorMessage = showErrorMessage ? 'show' : '';
  const isFocus = focus ? 'focus' : '';

  // function handlers
  const handleClick = () => {
    setfocus(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault(); // Prevent the default tab behavior (changing focus)
      inputRef.current.focus(); // Focus on the input element
    }
  };

  const handleBlur = (e) => {
    const val = e.target.value;
    onBlur();

    const emailRegex = /^(?!.*\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b).+$/;

    if (val === '' && mandatory) {
      setfocus(false);
      seterrorMessage(`bagian ${labelError ? labelError : label} wajib diisi`);
      setshowErrorMessage(true);
    } else if (val === '') {
      setfocus(false);
    } else {
      seterrorMessage('');
      setshowErrorMessage(true);

      if (type === 'email') {
        if (!emailRegex.test(val)) {
          seterrorMessage('');
          setshowErrorMessage(false);
        } else {
          seterrorMessage('format email yang dimasukkan salah');
          setshowErrorMessage(true);
        }
      }
    }
  };

  const handleChange = (e) => {

    if (type === 'number') {
      // Get the input value
      let inputValue = e.target.value;

      // Use a regular expression to keep only numeric characters
      let numericValue = inputValue.replace(/\D/g, '');

      // Update the input value with only numeric characters
      e.target.value = numericValue;
    }

    onChange(e);
  };

  return (
    <div className={`input-form ${className} ${isFocus} ${variant}`}>
      <label className='input-form__label'>
        {label}
      </label>

      <div className='input-form__trigger' onClick={handleClick}></div>
      <input placeholder={placeholder} ref={inputRef} className='input-form__input' type={"text"} maxLength={maxLength} name={name} onClick={handleClick} onBlur={handleBlur} onChange={handleChange} onKeyDown={handleKeyDown} value={value} />

      <span className={`input-form__error-warning ${isShowErrorMessage}`}>
        {errorMessage}
      </span>
    </div>
  );
}

InputForm.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  labelError: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string
};

InputForm.defaultProps = {
  className: '',
  type: '',
  label: '',
  labelError: '',
  name: '',
  min: 1,
  max: 1,
  value: '',
  onChange: () => {},
  errorMessage: '',
  placeholder: ''
};

export default InputForm;
