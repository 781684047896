// import React, { useState, useEffect } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const SurveyTemplate = ({ children }) => {

  const handleContextMenu = (e) => {
    // to disable right-click event
    // e.preventDefault();
  }
  return (
    <div className='sv-temp' onContextMenu={handleContextMenu}>
      {children}
    </div>
  );
}

SurveyTemplate.propTypes = {
  children: PropTypes.element.isRequired
};

SurveyTemplate.defaultProps = {
  children: null
};

export default SurveyTemplate;
