// import React, { useState, useEffect } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const InputGroup = ({ className, label, children }) => {
  return (
    <div className={`input-group ${className}`}>
      <label className='db mb-8 input-group__label'>{label}</label>
      {children}
    </div>
  );
}

InputGroup.propTypes = {
  label: PropTypes.string,
  children: PropTypes.element.isRequired
};

InputGroup.defaultProps = {
  label: '',
  children: null
};

export default InputGroup;
