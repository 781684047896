import React from 'react';
import { Button } from 'components';

import './styles.scss'

const ImageGallery = ({ multipleMaterial, multipleType, setshowPopup, style, formData, thumb, images, desc, setFormData, name, value, setisShowFloating, setimageDetailStyle }) => {

  const min = 1;
  const max = 1000;
  const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;

  const handleShowDetail = () => {
    document.body.style.overflow = 'hidden';
    setshowPopup(true);

    setTimeout(() => {
      setimageDetailStyle(images);
      setisShowFloating(false);
    }, 200);
  };

  const handleClickPilih = () => {

    if (multipleMaterial) {
      // If multiple selections are allowed, toggle the selected value
      setFormData((prevFormData) => {
        const materialBoard = prevFormData.materialBoard;
        if (materialBoard.includes(value)) {
          // If the value is already in the array, remove it
          return { ...prevFormData, materialBoard: materialBoard.filter(item => item !== value) };
        } else {
          // If the value is not in the array, add it
          return { ...prevFormData, materialBoard: [...materialBoard, value] };
        }
      });
    } else if (multipleType) {
      // If multiple selections are allowed, toggle the selected value
      setFormData((prevFormData) => {
        const type = prevFormData.type;
        if (type.includes(value)) {
          // If the value is already in the array, remove it
          return { ...prevFormData, type: type.filter(item => item !== value) };
        } else {
          // If the value is not in the array, add it
          return { ...prevFormData, type: [...type, value] };
        }
      });
    } else {
      // If multiple selections are not allowed, set the value directly
      setFormData({
        ...formData,
        [name]: value
      });
      setTimeout(() => {
        setisShowFloating(true);
      }, 300);
    }
  }

  const handleClickBatalkan = () => {

    if (multipleMaterial) {
      // If multiple selections are allowed, toggle the selected value
      setFormData((prevFormData) => {
        const materialBoard = prevFormData.materialBoard;
        if (materialBoard.includes(value)) {
          // If the value is already in the array, remove it
          return { ...prevFormData, materialBoard: materialBoard.filter(item => item !== value) };
        }
      });
    } else if (multipleType) {
      // If multiple selections are allowed, toggle the selected value
      setFormData((prevFormData) => {
        const type = prevFormData.type;
        if (type.includes(value)) {
          // If the value is already in the array, remove it
          return { ...prevFormData, type: type.filter(item => item !== value) };
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: ""
      });
    }
  }

  return (
    <div className="img-gal" key={`img-gal-${randomInt}`}>
      {multipleMaterial ? (
        <div className={`img-gal-thumb-wrapper ${style.includes(value) ? "selected" : ""}`}>
          <img className='img-gal-thumb' src={thumb} alt={desc} />
          <div className={`img-gal-thumb-ovr`}>
            <h4 className='img-gal-thumb-ovr-desc'>
              {desc}
            </h4>
            {style.includes(value) ? (
              <Button className='img-gal-thumb-ovr-btn' label='Batalkan' variant='white-transparent' onClick={handleClickBatalkan} />
            ) : (
              <div className='mt-16'>
                <Button className='img-gal-thumb-ovr-btn mr-8' label='Pilih' variant='primary' onClick={handleClickPilih} />
              </div>
            )}
          </div>
        </div>
      ) : multipleType ? (
        <div className={`img-gal-thumb-wrapper ${style.includes(value) ? "selected" : ""}`}>
          <img className='img-gal-thumb' src={thumb} alt={desc} />
          <div className={`img-gal-thumb-ovr`}>
            <h4 className='img-gal-thumb-ovr-desc'>
              {desc}
            </h4>
            {style.includes(value) ? (
              <Button className='img-gal-thumb-ovr-btn' label='Batalkan' variant='white-transparent' onClick={handleClickBatalkan} />
            ) : (
              <div className='mt-16'>
                <Button className='img-gal-thumb-ovr-btn mr-8' label='Pilih' variant='primary' onClick={handleClickPilih} />
                <Button className='img-gal-thumb-ovr-btn' label='Lihat Detail' variant='white-transparent' onClick={handleShowDetail} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={`img-gal-thumb-wrapper ${style === value ? "selected" : ""}`}>
          <img className='img-gal-thumb' src={thumb} alt={desc} />
          <div className={`img-gal-thumb-ovr`}>
            <h4 className='img-gal-thumb-ovr-desc'>
              {desc}
            </h4>
            {style === value ? (
              <Button className='img-gal-thumb-ovr-btn' label='Batalkan' variant='white-transparent' onClick={handleClickBatalkan} />
            ) : (
              <div className='mt-16'>
                <Button className='img-gal-thumb-ovr-btn mr-8' label='Pilih' variant='primary' onClick={handleClickPilih} />
                <Button className='img-gal-thumb-ovr-btn' label='Lihat Detail' variant='white-transparent' onClick={handleShowDetail} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
