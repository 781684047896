import {
  Homepage,
  Refresh,
  StylePreference,
  Questionnaire
} from 'containers/pages';

const AppRoutes = [
  {
    id: 0,
    path: '/',
    element: <Homepage/>,
    exact: true
  },
  {
    id: 1,
    path: '/refresh',
    element: <Refresh/>,
    exact: true
  },
  {
    id: 2,
    path: '/style',
    element: <StylePreference/>,
    exact: true
  },
  {
    id: 3,
    path: '/kuisioner',
    element: <Questionnaire/>,
    exact: true
  }
];

export default AppRoutes;
