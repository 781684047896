import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Button = ({
  className,
  label,
  variant,
  disabled,
  onClick,
  withArrowLeft
}) => {

  const classNames = `button ${className} ${variant}`;

  return (
    <button disabled={disabled} className={`${classNames} ${disabled ? 'disabled' : ''}`} onClick={onClick}>
      {withArrowLeft && <span className='arrow-left'></span>}
      {label}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func
};

Button.defaultProps = {
  className: '',
  type: '',
  label: '',
  name: '',
  onClick: () => {}
};

export default Button;
