import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Textarea = ({
  className,
  type,
  label,
  name,
  min,
  max,
  onChange,
  value,
  mandatory
}) => {

  const inputRef = useRef(null);

  // useStates
  const [focus, setfocus] = useState(false);
  const [showErrorMessage, setshowErrorMessage] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');

  // validator classes
  const isShowErrorMessage = showErrorMessage ? 'show' : '';
  const isFocus = focus ? 'focus' : '';

  // function handlers
  const handleClick = () => {
    setfocus(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleBlur = (e) => {
    const val = e.target.value;
    const textarea = e.target;

    if (val === '' && mandatory) {
      setfocus(false);
      seterrorMessage('this field is required');
      setshowErrorMessage(true);

      textarea.style.height = '40px';
      textarea.style.height = '40px';
    }
  };

  const handleChange = (e) => {
    const textarea = e.target;
    console.log(e.target.value);

    if (textarea.value === '') {
      textarea.style.height = '40px';
      textarea.style.height = '40px';
    }

    onChange(e);
  };

  const handleTextareaKeyDown = (e) => {
    const textarea = e.target;

    if (e.key === 'Enter') {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  };

  return (
    <div className={`textarea ${className} ${isFocus}`}>
      <label className='textarea__label'>
        {label}
      </label>

      <div className='textarea__trigger' onClick={handleClick}></div>

      <textarea
        ref={inputRef}
        className="textarea__input"
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleTextareaKeyDown}
        value={value}
      >{value}</textarea>

      <span className={`textarea__error-warning ${isShowErrorMessage}`}>
        {errorMessage}
      </span>
    </div>
  );
}

Textarea.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  value: PropTypes.string
};

Textarea.defaultProps = {
  className: '',
  type: '',
  label: '',
  name: '',
  min: 1,
  max: 1,
  onChange: () => {},
  errorMessage: '',
  value: ''
};

export default Textarea;
