// imageUrls.js

const publicURL = process.env.PUBLIC_URL;

// const publicURL = process.env.REACT_APP_CUSTOM_PUBLIC_URL;
const imageUrls = {
  logoBig: publicURL + '/images/logo-big.png',
  logo: publicURL + '/images/logo.png',
  logoManna: publicURL + '/images/logo-manna.png',
  gifLoaderLogo: publicURL + '/images/logo-loader-ood.gif',
  ambience: publicURL + '/images/Ambience/Bright/2de02dab9c1b7b22b9afe6bfab953b98.jpg',
  bgCoverStart: publicURL + '/images/bg.png',
  // ambience bright
  ambienceBright: publicURL + '/images/Ambience/Bright/2de02dab9c1b7b22b9afe6bfab953b98.jpg',
  ambienceBrightList: [
    publicURL + '/images/Ambience/Bright/2de02dab9c1b7b22b9afe6bfab953b98.jpg',
    publicURL + '/images/Ambience/Bright/617c0b4f8b838ded1fa0f033f37de5d4.jpg',
    publicURL + '/images/Ambience/Bright/7a8bca28606ffba4869b35f0003a2a4c.jpg',
    publicURL + '/images/Ambience/Bright/b7b1f175f02b9634804cb51e0d0d4b88.jpg',
    publicURL + '/images/Ambience/Bright/3ff3b38bcbe67dcd02c6b2d173dd7466.jpg',
    publicURL + '/images/Ambience/Bright/777b659649c526ce429b7c09a8d5f581.jpg',
    publicURL + '/images/Ambience/Bright/b371666dc79d4655470af97fd059b97b.jpg',
    publicURL + '/images/Ambience/Bright/fa23c69fd6596f0c89d87e0a0ee0f7af.jpg'
  ],
  // ambience dark
  ambienceDark: publicURL + '/images/Ambience/Dark/0f25469754f5ebe8a51aad09954e5ab5.jpg',
  ambienceDarkList: [
    publicURL + '/images/Ambience/Dark/0f25469754f5ebe8a51aad09954e5ab5.jpg',
    publicURL + '/images/Ambience/Dark/4d6c785343e8075e7b55025833be43f2.jpg',
    publicURL + '/images/Ambience/Dark/80302a850e6b917d5d0dcf8c60217059.jpg',
    publicURL + '/images/Ambience/Dark/e1a6dd88ab8c6816c2ec65a9ebcadcce.jpg',
    publicURL + '/images/Ambience/Dark/1fe76b0413f25b3961539d2f8b1a36a1.jpg',
    publicURL + '/images/Ambience/Dark/5357b0018b9ca37d50a81f15ab8b9b5d.jpg',
    publicURL + '/images/Ambience/Dark/baaa0afe850709dcd6b5186cf071bbd1.jpg'
  ],
  // ambience dark
  ambienceWarm: publicURL + '/images/Ambience/Warm/147b310cd6540508682f676e86d825ad.jpg',
  ambienceWarmList: [
    publicURL + '/images/Ambience/Warm/147b310cd6540508682f676e86d825ad.jpg',
    publicURL + '/images/Ambience/Warm/56551ad829736fa7893b330649e6a35a.jpg',
    publicURL + '/images/Ambience/Warm/6826325e62b774deb862913a0b6621da.jpg',
    publicURL + '/images/Ambience/Warm/872bfe671519a2e207b566898e06d581.jpg',
    publicURL + '/images/Ambience/Warm/4f349db96d0805f2f4d29897af8c52ae.jpg',
    publicURL + '/images/Ambience/Warm/5c8c9b657a1222a8270aa33d6628b1bd.jpg',
    publicURL + '/images/Ambience/Warm/7d6233fe245203ba6ce31e7cd25c8003.jpg',
    publicURL + '/images/Ambience/Warm/d2b10d4759f946cd5cfc0dbbfe93c0e7.jpg'
  ],
  // bohemian
  bohemian: publicURL + '/images/Bohemian/29e08561edc257543dfbe39a54c8816a.jpg',
  bohemianList: [
    publicURL + '/images/Bohemian/29e08561edc257543dfbe39a54c8816a.jpg',
    publicURL + '/images/Bohemian/66d614e333ee0e6e8e7b1a0276ae58d7.jpg',
    publicURL + '/images/Bohemian/ad4a4d0a2254948d8f239bfa5ec3824b.jpg',
    publicURL + '/images/Bohemian/dabd554d89b1ee4349204552e7009835.jpg',
    publicURL + '/images/Bohemian/559b5f72dc0c80bbab3531d07bad7b87.jpg',
    publicURL + '/images/Bohemian/6c7c36a2f621a5ecfa37a4c5fde45853.jpg',
    publicURL + '/images/Bohemian/b4b585aa5ff24dbb3da1ee7b10af959e.jpg',
    publicURL + '/images/Bohemian/fef142fe7ec29508907cb588c7d8b498.jpg',
    publicURL + '/images/Bohemian/66d337dd285f44b5edb4b95cece37afc.jpg',
    publicURL + '/images/Bohemian/733205342c8b3dbffb0c07495b5722c7.jpg',
    publicURL + '/images/Bohemian/cbf409d1c0d422e5f754cb547f3a7596.jpg'
  ],
  // dark interior
  darkInterior: publicURL + '/images/DarkInterior/09ecfb2268d7a97b4b73a6c741c5a3c6.jpg',
  darkInteriorList: [
    publicURL + '/images/DarkInterior/09ecfb2268d7a97b4b73a6c741c5a3c6.jpg',
    publicURL + '/images/DarkInterior/4d4a5704a27a3940cc132c41c56f5936.jpg',
    publicURL + '/images/DarkInterior/c56ef6122abac4b7aca82de5b71e6087.jpg',
    publicURL + '/images/DarkInterior/f6622dc08c5092b1b57ef5fa5adb307f.jpg',
    publicURL + '/images/DarkInterior/23c7e94769482180d9e1aa281748acae.jpg',
    publicURL + '/images/DarkInterior/6c1815e3698b00591a16c1b6b3ffe094.jpg',
    publicURL + '/images/DarkInterior/cc0d31b11b7311f75f0124e92c14e30b.jpg',
    publicURL + '/images/DarkInterior/3e95920f1c523b97b714358cebee3756.jpg',
    publicURL + '/images/DarkInterior/99ba0b3370eb4844e8fc3709d6be4743.jpg',
    publicURL + '/images/DarkInterior/e25c3f8eb1dc3fb7975daada420c8019.jpg'
  ],
  // detail
  detail: publicURL + '/images/Detail/Detail/0dbd8c3b56054e84b14b7ca132ce90c6.jpg',
  detailList: [
    publicURL + '/images/Detail/Detail/0dbd8c3b56054e84b14b7ca132ce90c6.jpg',
    publicURL + '/images/Detail/Detail/65b65f7e795e98b3084be13e19dccd28.jpg',
    publicURL + '/images/Detail/Detail/b1e3eaceb701e42d2140845c97366c32.jpg',
    publicURL + '/images/Detail/Detail/cdb71e283eb29cd7d1d6edc8e0a29fa9.jpg',
    publicURL + '/images/Detail/Detail/35de445a513e3b7b24cea9215738cf46.jpg',
    publicURL + '/images/Detail/Detail/80a239e69d8cd5768c5b68364e5fe833.jpg',
    publicURL + '/images/Detail/Detail/ba8ab8d4dee90a0a5ea628657f1ff161.jpg'
  ],
  // simple
  simple: publicURL + '/images/Detail/Simple/156d1980715688448e67c5891a48ef4a.jpg',
  simpleList: [
    publicURL + '/images/Detail/Simple/156d1980715688448e67c5891a48ef4a.jpg',
    publicURL + '/images/Detail/Simple/39bbd7a7a91c965eef253aa0e105ae51.jpg',
    publicURL + '/images/Detail/Simple/db5e75ec391d65a8fe1c868c0bbed9a4.jpg',
    publicURL + '/images/Detail/Simple/246469b34a9110c29b780ea09a2bf958.jpg',
    publicURL + '/images/Detail/Simple/419f64aa841b6d961115448a4f2ccde4.jpg',
    publicURL + '/images/Detail/Simple/f52a4ca55859c3ec1d896502bdb7edef.jpg',
    publicURL + '/images/Detail/Simple/2ec5223dbca48af590e79165c48237b9.jpg',
    publicURL + '/images/Detail/Simple/9ab5af332153df2c04433e5af77f216c.jpg',
    publicURL + '/images/Detail/Simple/fb55dd59cdbfcbcd0d9ec54a1964f5a4.jpg'
  ],
  // farmhouse
  farmhouse: publicURL + '/images/Farmhouse/0b4761b30d5a6b2b100a36240cd999c4.jpg',
  farmhouseList: [
    publicURL + '/images/Farmhouse/0b4761b30d5a6b2b100a36240cd999c4.jpg',
    publicURL + '/images/Farmhouse/315cc7627de784a56d49952539aab9e8.jpg',
    publicURL + '/images/Farmhouse/9a91c5292d3ac4c1a58b49017ebcc8b0.jpg',
    publicURL + '/images/Farmhouse/f7d064fb2cf8436b700e7bdb668dca3d.jpg',
    publicURL + '/images/Farmhouse/24991dbf759aa3cef2a120eb98076a9d.jpg',
    publicURL + '/images/Farmhouse/3a18f85431c1c5c5bc707812dcc35994.jpg',
    publicURL + '/images/Farmhouse/9d3122545887d9416dd925be5c3fd7fa.jpg',
    publicURL + '/images/Farmhouse/f94ef8864b7870b5f61a56e5ce96fd96.jpg',
    publicURL + '/images/Farmhouse/2ffd390af4f5dd387f924e3c97f2b7db.jpg',
    publicURL + '/images/Farmhouse/458ba8f3609ba7a613d721b76da6b106.jpg',
    publicURL + '/images/Farmhouse/f2e18f22514dcb9105f2a7330a6562f3.jpg',
    publicURL + '/images/Farmhouse/fba40014d20d8e3cd7459b2c0370fa9c.jpg'
  ],
  // klasik modern
  klasikModern: publicURL + '/images/KlasikModern/00cefe4c0b855bdb37383fa41a9c7506.jpg',
  klasikModernList: [
    publicURL + '/images/KlasikModern/00cefe4c0b855bdb37383fa41a9c7506.jpg',
    publicURL + '/images/KlasikModern/2a1bdf21934550c3a44b6196c39129bf.jpg',
    publicURL + '/images/KlasikModern/73cc55a748d0c5a47195b833594b2e19.jpg',
    publicURL + '/images/KlasikModern/b18117d346d6c160445c571efa635519.jpg',
    publicURL + '/images/KlasikModern/16d2234a466c8e3b15f766e930df66a2.jpg',
    publicURL + '/images/KlasikModern/6a6e5f64bf8aa70b87432965241f8817.jpg',
    publicURL + '/images/KlasikModern/75fc37bd20446682f7c8dac79810e3a4.jpg',
    publicURL + '/images/KlasikModern/1fb2f740358ba906c3d1bd4b27350534.jpg',
    publicURL + '/images/KlasikModern/6c1bf6d76bb88e08f5616b5e3ad5b031.jpg',
    publicURL + '/images/KlasikModern/9bf488f9bc14867d18e879f4ceacec2c.jpg'
  ],
  // material board
  materialBoard: publicURL + '/images/MaterialBoard/0304a0523bc1b64d0dcd17ae742f1a6d.jpg',
  materialBoardList: [
    publicURL + '/images/MaterialBoard/0304a0523bc1b64d0dcd17ae742f1a6d.jpg',
    publicURL + '/images/MaterialBoard/0ea10e1b69cc62a743340dae6fbbeb15.jpg',
    publicURL + '/images/MaterialBoard/2503ccba881a0799a707c13749feea4a.jpg',
    publicURL + '/images/MaterialBoard/27c01fd6d7b88fbf4e48d55b2c7a51fc.jpg',
    publicURL + '/images/MaterialBoard/339bf2376a1d2dea83608b15ad2f1e29.jpg',
    publicURL + '/images/MaterialBoard/3d12b5a4fc5a72e11d7f960b660b36fa.jpg',
    publicURL + '/images/MaterialBoard/5bd2e70d4b8799b85954b28b483d859b.jpg',
    publicURL + '/images/MaterialBoard/5cbb0710190888db67abb7d7bf532b03.jpg',
    publicURL + '/images/MaterialBoard/6a0dd3fb9fb5623f18c7af0a71a02dc3.jpg',
    publicURL + '/images/MaterialBoard/71b33bca46efd6cff4843e6c3798ef5f.jpg',
    publicURL + '/images/MaterialBoard/92941870334724358a2e269ebfc38558.jpg',
    publicURL + '/images/MaterialBoard/92ff6cf22682fadd9b36bf64705b5d01.jpg',
    publicURL + '/images/MaterialBoard/930451aab489d164bab004e193ce5aae.jpg',
    publicURL + '/images/MaterialBoard/9cc48e65da41b7e6e8a638def3c1e394.jpg',
    publicURL + '/images/MaterialBoard/9cf888bdef08bc27650b02429a4b9bd9.jpg',
    publicURL + '/images/MaterialBoard/9e3bdfad7546e587f19778eb556707fb.jpg',
    publicURL + '/images/MaterialBoard/a222ab2c54dbbbe003188248201e4cfe.jpg',
    publicURL + '/images/MaterialBoard/b3ecfa8f397186458054d107e99ce80e.jpg',
    publicURL + '/images/MaterialBoard/c31f7e04fc02fae56f3daf80fd78a79d.jpg',
    publicURL + '/images/MaterialBoard/c9f61c4cb2c2cfb8ab1f6921fa82d613.jpg',
    publicURL + '/images/MaterialBoard/d89cf21778473f89e853141adaaf86e9.jpg',
    publicURL + '/images/MaterialBoard/e25ec5daceb45356add4a23c50e9c38a.jpg',
    publicURL + '/images/MaterialBoard/e68d5131fdac56759c8e9444c24ca28e.jpg',
    publicURL + '/images/MaterialBoard/ea70ae1fba6f173f28f11ccdd68113b3.jpg',
    publicURL + '/images/MaterialBoard/f6113120d8ebe4eda7b635da4abbfe65.jpg'
  ],
  // modern elegan
  modernElegan: publicURL + '/images/ModernElegan/009aba50ee3aef0114ee2b67b1dac837.jpg',
  modernEleganList: [
    publicURL + '/images/ModernElegan/009aba50ee3aef0114ee2b67b1dac837.jpg',
    publicURL + '/images/ModernElegan/102beea7e8834ca704a3db6f8caa0a1e.jpg',
    publicURL + '/images/ModernElegan/899697505aec3383a147154c4f864927.jpg',
    publicURL + '/images/ModernElegan/9a7ad85e44c74def028d6c56545ada8a.jpg',
    publicURL + '/images/ModernElegan/ec00209ded3d6499bb68ceede3f7b784.jpg'
  ],
  // modern industrial
  modernIndustrial: publicURL + '/images/ModernIndustrial/055d9c5056baa924f2119863aa0a2e64.jpg',
  modernIndustrialList: [
    publicURL + '/images/ModernIndustrial/055d9c5056baa924f2119863aa0a2e64.jpg',
    publicURL + '/images/ModernIndustrial/070be4cfed831881193e47a2c4063b4d.jpg',
    publicURL + '/images/ModernIndustrial/1b746285e9d2e6772775061e99b14b53.jpg',
    publicURL + '/images/ModernIndustrial/381e9376c5d4a67157efb32c57e1adc1.jpg',
    // publicURL + '/images/ModernIndustrial/3c1188d0c0e9423e3bad7b7268a75931.jpg',
    publicURL + '/images/ModernIndustrial/3c7749705cbba0cd081689c3897309cb.jpg',
    publicURL + '/images/ModernIndustrial/499ee748b4ae139ffa6639c9c789f32e.jpg',
    publicURL + '/images/ModernIndustrial/82e9262c8ac29af7990afcaf8769fdfa.jpg',
    publicURL + '/images/ModernIndustrial/8305ec6b0cf18dff0a7ee2a731d52ef8.jpg',
    publicURL + '/images/ModernIndustrial/8f2bcd93533e33362b2fe1a151b6e898.jpg',
    publicURL + '/images/ModernIndustrial/d2a101ca87cad9a7cdc613df1c16e56f.jpg',
    publicURL + '/images/ModernIndustrial/f852c66de955f4320bd419adade604a4.jpg'
  ],
  // modern japandi
  modernJapandi: publicURL + '/images/ModernJapandi/1482de359d619bcba70f73983ed9775a.jpg',
  modernJapandiList: [
    publicURL + '/images/ModernJapandi/1482de359d619bcba70f73983ed9775a.jpg',
    publicURL + '/images/ModernJapandi/161886c45805b213af3348141fbf4077.jpg',
    publicURL + '/images/ModernJapandi/1982baf8769670f1f8f029ef1fcbe4cc.jpg',
    publicURL + '/images/ModernJapandi/2b2dea4d9c03e0c45381ff176f33bcc3.jpg',
    publicURL + '/images/ModernJapandi/3625ba12f1f72028c116ce258a029a07.jpg',
    publicURL + '/images/ModernJapandi/5c8c9b657a1222a8270aa33d6628b1bd.jpg',
    publicURL + '/images/ModernJapandi/5ca5946a5570cc9fec6365b2045c43ce66.jpg',
    publicURL + '/images/ModernJapandi/70a6dd631228932fc65f2be3dd062d6b.jpg',
    publicURL + '/images/ModernJapandi/a4fa85d96d648523486f6a61af11c0ee.jpg',
    publicURL + '/images/ModernJapandi/b5771d3912ea3e26b97ff5bdc58173da.jpg',
    publicURL + '/images/ModernJapandi/c0b9f3f26c46c8bf9c41745fd50a2dc6.jpg',
    publicURL + '/images/ModernJapandi/ca9dcf7c1c906d4ca2c5c242f687c9d0.jpg',
    publicURL + '/images/ModernJapandi/dc705fa2e1d9e810d6f6ea9e8fc05b52.jpg',
    publicURL + '/images/ModernJapandi/e6a2477dd72bedbb782a480a08dbf098.jpg',
    publicURL + '/images/ModernJapandi/f19de142747afa6b7b366ffe867958c8.jpg'
  ],
  // modern kontemporer
  modernKontemporer: publicURL + '/images/ModernKontemporer/012022b3dd07f33f12c428b58e064432.jpg',
  modernKontemporerList: [
    publicURL + '/images/ModernKontemporer/012022b3dd07f33f12c428b58e064432.jpg',
    publicURL + '/images/ModernKontemporer/07450602abab553f8e12624af954f086.jpg',
    publicURL + '/images/ModernKontemporer/11ee77718dfce6337f4a69f7a89c7000.jpg',
    publicURL + '/images/ModernKontemporer/1c9fcf483d7df136cc6af9bf00480241.jpg',
    publicURL + '/images/ModernKontemporer/2e5d24e7756eacd7c00cbb6551fb8b88.jpg',
    publicURL + '/images/ModernKontemporer/85b5000092b521f53da8ae6927b97c84.jpg',
    publicURL + '/images/ModernKontemporer/9e2b10f0c0c4058be0f3e195f0b9718b.jpg',
    publicURL + '/images/ModernKontemporer/ade6fb75446333d73bd3c3d97ab7fe78.jpg',
    publicURL + '/images/ModernKontemporer/c5c3a8a7268f99896bb144d05f837ce1.jpg',
    publicURL + '/images/ModernKontemporer/d70605e287f3963317fa2a9753a9b5f6.jpg',
    publicURL + '/images/ModernKontemporer/deb76e103d04cc6e474cbb36406e23ef.jpg',
    publicURL + '/images/ModernKontemporer/df289c831ee21a5fd5171ee319895d0f.jpg',
    publicURL + '/images/ModernKontemporer/e9a02f1a5729b582b9cd5fd1eaa0ba56.jpg',
    publicURL + '/images/ModernKontemporer/ebf8d4cf4a009dbfd8e2f97b5912898e.jpg',
    publicURL + '/images/ModernKontemporer/f5aaf539b0fa3151ea0bef7b35da8b73.jpg'
  ],
  // modern luxury
  modernLuxury: publicURL + '/images/ModernLuxury/066ad110cea71523698a88fdef171bc5.jpg',
  modernLuxuryList: [
    publicURL + '/images/ModernLuxury/066ad110cea71523698a88fdef171bc5.jpg',
    publicURL + '/images/ModernLuxury/0b574544c6bcc378f0e4cf55909c5887.jpg',
    publicURL + '/images/ModernLuxury/0db5f5f819ba404537d5b9283083da81.jpg',
    publicURL + '/images/ModernLuxury/2c838dfe50313103f55e7bf56656e199.jpg',
    publicURL + '/images/ModernLuxury/2fe0e1e47836ccaa5205dddc0a1671b4.jpg',
    publicURL + '/images/ModernLuxury/6ddd762d8199b4dd781005ed6c70ec72.jpg',
    publicURL + '/images/ModernLuxury/7c04ec441779c28dde02b44a386f41aa.jpg',
    publicURL + '/images/ModernLuxury/99213a2abe33c1f5197d806405e74d2f.jpg',
    publicURL + '/images/ModernLuxury/b984cdb29dde8942ef3d9bcde2a80cb5.jpg',
    publicURL + '/images/ModernLuxury/d22ff75b4a6819a787c52945a2065bcb.jpg',
    publicURL + '/images/ModernLuxury/d4fedc309fc1f2832cbd1997f85fcbaa.jpg',
    publicURL + '/images/ModernLuxury/db0d44db5a5824dc4a50ddd0caa38aa6.jpg'
  ],
  // modern nature
  modernNature: publicURL + '/images/ModernNature/02ed84e28bb3729e93d91994ddc29bfd.jpg',
  modernNatureList: [
    publicURL + '/images/ModernNature/02ed84e28bb3729e93d91994ddc29bfd.jpg',
    publicURL + '/images/ModernNature/57bfe598e6557c8a22cba2df17523dd5.jpg',
    publicURL + '/images/ModernNature/6a64f71faa97f3b00f9b758388686da7.jpg',
    publicURL + '/images/ModernNature/6f3c09452609c4bdfda9cad3fbe4ac3f.jpg',
    publicURL + '/images/ModernNature/88ee12c33dc74a44678767f26793e13a.jpg',
    publicURL + '/images/ModernNature/b25a2fcfdc2df63d8ec65b8ce3403c07.jpg',
    publicURL + '/images/ModernNature/cf268a7037ffdd51255cf349d5a45bfd.jpg',
    publicURL + '/images/ModernNature/d0d604f62308cf6841f00566a39a91a8.jpg'
  ],
  // modern oriental
  modernOriental: publicURL + '/images/ModernOriental/13ebb6f86f157dc413b18bbf9a9858c2.jpg',
  modernOrientalList: [
    publicURL + '/images/ModernOriental/13ebb6f86f157dc413b18bbf9a9858c2.jpg',
    publicURL + '/images/ModernOriental/21ab42168c9b655b416355f52277cceb.jpg',
    publicURL + '/images/ModernOriental/50ceb6f1aae030f6c429cae08ba76a22.jpg',
    publicURL + '/images/ModernOriental/948ef4bacf899990ac00d27a9b1d2257.jpg',
    publicURL + '/images/ModernOriental/9f9932b98b618e39cd4330d550ccd8e2.jpg',
    publicURL + '/images/ModernOriental/a37a4303bfd2a45f2684fbf4fe372785.jpg',
    publicURL + '/images/ModernOriental/b526a7ddcc6d7ac045c0d5c70bd02282.jpg',
    publicURL + '/images/ModernOriental/b52ccaa0c77dcd4e7b0078c6b12b3053.jpg',
    publicURL + '/images/ModernOriental/e968f49674c9e4770aa8a035d2bc5a3d.jpg'
  ],
  // popart
  popArt: publicURL + '/images/PopArt/362b9684254065a9f96f787963467c41.jpg',
  popArtList: [
    publicURL + '/images/PopArt/362b9684254065a9f96f787963467c41.jpg',
    publicURL + '/images/PopArt/740b5554862ad315a2b2e4341f7f6a6d.jpg',
    publicURL + '/images/PopArt/767d3a3c8728005c04baa135468a1acf.jpg',
    publicURL + '/images/PopArt/772ba3133945cff55c584c98ecdc07cc.jpg',
    publicURL + '/images/PopArt/7a65cbcf3d2da7eab730254f35e40959.jpg',
    publicURL + '/images/PopArt/a10bc2455b2bd3538dba3de8b198df4a.jpg',
    publicURL + '/images/PopArt/b5fe22a1e0dd2339ce8e04a327f62508.jpg',
    publicURL + '/images/PopArt/fe3e2682b4a7888fb89c4f5aa78bb1e1.jpg'
  ],
  wabiSabi: publicURL + '/images/WabiSabi/5d7d35316ea414ef19af58ef04e64215.jpg',
  wabiSabiList: [
    publicURL + '/images/WabiSabi/5d7d35316ea414ef19af58ef04e64215.jpg',
    publicURL + '/images/WabiSabi/54ccd3446d63afcdbeaeeafb61f3020c.jpg',
    publicURL + '/images/WabiSabi/659ed440425d1c219b2e1cf3ced41eb0.jpg',
    publicURL + '/images/WabiSabi/ae2dd39a29a5b2ac7fdfe414e0b0f71a.jpg',
    publicURL + '/images/WabiSabi/b25acfa2a59f0b29be0341de11a1e83a.jpg',
    publicURL + '/images/WabiSabi/bba92de3f2ec97831c2b1e2a8f130335.jpg',
    publicURL + '/images/WabiSabi/e655fb6f9772256447a3f3065b89e0f2.jpg',
    publicURL + '/images/WabiSabi/f172b4a6ebb1271051791d7492c1ded1.jpg'
  ],
  // shape curve
  shapeCurve: publicURL + '/images/Shape/Curve/4de723b86420bbe95a45eeecc89048e6.jpg',
  shapeCurveList: [
    publicURL + '/images/Shape/Curve/4de723b86420bbe95a45eeecc89048e6.jpg',
    publicURL + '/images/Shape/Curve/522941ff569ccebae00a1b001a7e35c2.jpg',
    publicURL + '/images/Shape/Curve/5c09ee94c0196f6b0605abfbd42f6beb.jpg',
    publicURL + '/images/Shape/Curve/6ce60edd51d07f7013620a0605bfded4.jpg',
    publicURL + '/images/Shape/Curve/7d7b1e6524cf6f2b41f79ffcefe08b69.jpg',
    publicURL + '/images/Shape/Curve/a04cf33bfec3989b5a3f4316035c5c52.jpg',
    publicURL + '/images/Shape/Curve/b086661671663fbc40cc10783b3e5213.jpg',
    publicURL + '/images/Shape/Curve/c06f9c39e508ab9f81eebd78af5818e3.jpg',
    publicURL + '/images/Shape/Curve/d6ee736573a95ffe1144bf80f2e5fae4.jpg',
    publicURL + '/images/Shape/Curve/fad5d3bdf4c9ecafe84403d671e54b06.jpg'
  ],
  // shape square
  shapeSquare: publicURL + '/images/Shape/Square/62c0fc982af5be1fd2b10cb48f21c0a4.jpg',
  shapeSquareList: [
    publicURL + '/images/Shape/Square/62c0fc982af5be1fd2b10cb48f21c0a4.jpg',
    publicURL + '/images/Shape/Square/9be8249ae586c256c368e96e7493080d.jpg',
    publicURL + '/images/Shape/Square/b9acd95ea7ede5de404579693c7ca32f.jpg',
    publicURL + '/images/Shape/Square/c72f4e70c5c21ec643b533bab1310816.jpg',
    publicURL + '/images/Shape/Square/dd2e9f1e9286fcd316af516e6afdcb85.jpg',
    publicURL + '/images/Shape/Square/eb58f0dc56bf5b0de21a28ab542141bd.jpg'
  ],
  // shape triangle
  shapeTriangle: publicURL + '/images/Shape/Triagle/04b1016c2902a3e562c95f41d6945f80.jpg',
  shapeTriangleList: [
    publicURL + '/images/Shape/Triagle/04b1016c2902a3e562c95f41d6945f80.jpg',
    publicURL + '/images/Shape/Triagle/0f888f0644a51839a0b18a2c8b57bf85.jpg',
    publicURL + '/images/Shape/Triagle/393cb2c2da7b5a59cc0e0eb5664d34fa.jpg',
    publicURL + '/images/Shape/Triagle/4577e4161522b481a42099bb196e0fd1.jpg',
    publicURL + '/images/Shape/Triagle/46b14af6d6360a01c404b6a7c05324e1.jpg',
    publicURL + '/images/Shape/Triagle/5c09ee94c0196f6b0605abfbd42f6beb.jpg',
    publicURL + '/images/Shape/Triagle/734850cba7f2e674e9e883b814a352b9.jpg',
    publicURL + '/images/Shape/Triagle/aec40f15ea769ac1e3ff356e1e981e22.jpg',
    publicURL + '/images/Shape/Triagle/d13cea004235f159f7ee30d7bd7e4552.jpg'
  ]

  // ambience bright
  // ambienceBright: publicURL + '/images/watermarked/2de02dab9c1b7b22b9afe6bfab953b98.jpg',
  // ambienceBrightList: [
  //   publicURL + '/images/watermarked/2de02dab9c1b7b22b9afe6bfab953b98.jpg',
  //   publicURL + '/images/watermarked/617c0b4f8b838ded1fa0f033f37de5d4.jpg',
  //   publicURL + '/images/watermarked/7a8bca28606ffba4869b35f0003a2a4c.jpg',
  //   publicURL + '/images/watermarked/b7b1f175f02b9634804cb51e0d0d4b88.jpg',
  //   publicURL + '/images/watermarked/3ff3b38bcbe67dcd02c6b2d173dd7466.jpg',
  //   publicURL + '/images/watermarked/777b659649c526ce429b7c09a8d5f581.jpg',
  //   publicURL + '/images/watermarked/b371666dc79d4655470af97fd059b97b.jpg',
  //   publicURL + '/images/watermarked/fa23c69fd6596f0c89d87e0a0ee0f7af.jpg'
  // ],
  // // ambience dark
  // ambienceDark: publicURL + '/images/watermarked/0f25469754f5ebe8a51aad09954e5ab5.jpg',
  // ambienceDarkList: [
  //   publicURL + '/images/watermarked/0f25469754f5ebe8a51aad09954e5ab5.jpg',
  //   publicURL + '/images/watermarked/4d6c785343e8075e7b55025833be43f2.jpg',
  //   publicURL + '/images/watermarked/80302a850e6b917d5d0dcf8c60217059.jpg',
  //   publicURL + '/images/watermarked/e1a6dd88ab8c6816c2ec65a9ebcadcce.jpg',
  //   publicURL + '/images/watermarked/1fe76b0413f25b3961539d2f8b1a36a1.jpg',
  //   publicURL + '/images/watermarked/5357b0018b9ca37d50a81f15ab8b9b5d.jpg',
  //   publicURL + '/images/watermarked/baaa0afe850709dcd6b5186cf071bbd1.jpg'
  // ],
  // // ambience dark
  // ambienceWarm: publicURL + '/images/watermarked/147b310cd6540508682f676e86d825ad.jpg',
  // ambienceWarmList: [
  //   publicURL + '/images/watermarked/147b310cd6540508682f676e86d825ad.jpg',
  //   publicURL + '/images/watermarked/56551ad829736fa7893b330649e6a35a.jpg',
  //   publicURL + '/images/watermarked/6826325e62b774deb862913a0b6621da.jpg',
  //   publicURL + '/images/watermarked/872bfe671519a2e207b566898e06d581.jpg',
  //   publicURL + '/images/watermarked/4f349db96d0805f2f4d29897af8c52ae.jpg',
  //   publicURL + '/images/watermarked/5c8c9b657a1222a8270aa33d6628b1bd.jpg',
  //   publicURL + '/images/watermarked/7d6233fe245203ba6ce31e7cd25c8003.jpg',
  //   publicURL + '/images/watermarked/d2b10d4759f946cd5cfc0dbbfe93c0e7.jpg'
  // ],
  // // bohemian
  // bohemian: publicURL + '/images/watermarked/29e08561edc257543dfbe39a54c8816a.jpg',
  // bohemianList: [
  //   publicURL + '/images/watermarked/29e08561edc257543dfbe39a54c8816a.jpg',
  //   publicURL + '/images/watermarked/66d614e333ee0e6e8e7b1a0276ae58d7.jpg',
  //   publicURL + '/images/watermarked/ad4a4d0a2254948d8f239bfa5ec3824b.jpg',
  //   publicURL + '/images/watermarked/dabd554d89b1ee4349204552e7009835.jpg',
  //   publicURL + '/images/watermarked/559b5f72dc0c80bbab3531d07bad7b87.jpg',
  //   publicURL + '/images/watermarked/6c7c36a2f621a5ecfa37a4c5fde45853.jpg',
  //   publicURL + '/images/watermarked/b4b585aa5ff24dbb3da1ee7b10af959e.jpg',
  //   publicURL + '/images/watermarked/fef142fe7ec29508907cb588c7d8b498.jpg',
  //   publicURL + '/images/watermarked/66d337dd285f44b5edb4b95cece37afc.jpg',
  //   publicURL + '/images/watermarked/733205342c8b3dbffb0c07495b5722c7.jpg',
  //   publicURL + '/images/watermarked/cbf409d1c0d422e5f754cb547f3a7596.jpg'
  // ],
  // // dark interior
  // darkInterior: publicURL + '/images/watermarked/09ecfb2268d7a97b4b73a6c741c5a3c6.jpg',
  // darkInteriorList: [
  //   publicURL + '/images/watermarked/09ecfb2268d7a97b4b73a6c741c5a3c6.jpg',
  //   publicURL + '/images/watermarked/4d4a5704a27a3940cc132c41c56f5936.jpg',
  //   publicURL + '/images/watermarked/c56ef6122abac4b7aca82de5b71e6087.jpg',
  //   publicURL + '/images/watermarked/f6622dc08c5092b1b57ef5fa5adb307f.jpg',
  //   publicURL + '/images/watermarked/23c7e94769482180d9e1aa281748acae.jpg',
  //   publicURL + '/images/watermarked/6c1815e3698b00591a16c1b6b3ffe094.jpg',
  //   publicURL + '/images/watermarked/cc0d31b11b7311f75f0124e92c14e30b.jpg',
  //   publicURL + '/images/watermarked/3e95920f1c523b97b714358cebee3756.jpg',
  //   publicURL + '/images/watermarked/99ba0b3370eb4844e8fc3709d6be4743.jpg',
  //   publicURL + '/images/watermarked/e25c3f8eb1dc3fb7975daada420c8019.jpg'
  // ],
  // // detail
  // detail: publicURL + '/images/watermarked/0dbd8c3b56054e84b14b7ca132ce90c6.jpg',
  // detailList: [
  //   publicURL + '/images/watermarked/0dbd8c3b56054e84b14b7ca132ce90c6.jpg',
  //   publicURL + '/images/watermarked/65b65f7e795e98b3084be13e19dccd28.jpg',
  //   publicURL + '/images/watermarked/b1e3eaceb701e42d2140845c97366c32.jpg',
  //   publicURL + '/images/watermarked/cdb71e283eb29cd7d1d6edc8e0a29fa9.jpg',
  //   publicURL + '/images/watermarked/35de445a513e3b7b24cea9215738cf46.jpg',
  //   publicURL + '/images/watermarked/80a239e69d8cd5768c5b68364e5fe833.jpg',
  //   publicURL + '/images/watermarked/ba8ab8d4dee90a0a5ea628657f1ff161.jpg'
  // ],
  // // simple
  // simple: publicURL + '/images/watermarked/156d1980715688448e67c5891a48ef4a.jpg',
  // simpleList: [
  //   publicURL + '/images/watermarked/156d1980715688448e67c5891a48ef4a.jpg',
  //   publicURL + '/images/watermarked/39bbd7a7a91c965eef253aa0e105ae51.jpg',
  //   publicURL + '/images/watermarked/db5e75ec391d65a8fe1c868c0bbed9a4.jpg',
  //   publicURL + '/images/watermarked/246469b34a9110c29b780ea09a2bf958.jpg',
  //   publicURL + '/images/watermarked/419f64aa841b6d961115448a4f2ccde4.jpg',
  //   publicURL + '/images/watermarked/f52a4ca55859c3ec1d896502bdb7edef.jpg',
  //   publicURL + '/images/watermarked/2ec5223dbca48af590e79165c48237b9.jpg',
  //   publicURL + '/images/watermarked/9ab5af332153df2c04433e5af77f216c.jpg',
  //   publicURL + '/images/watermarked/fb55dd59cdbfcbcd0d9ec54a1964f5a4.jpg'
  // ],
  // // farmhouse
  // farmhouse: publicURL + '/images/watermarked/0b4761b30d5a6b2b100a36240cd999c4.jpg',
  // farmhouseList: [
  //   publicURL + '/images/watermarked/0b4761b30d5a6b2b100a36240cd999c4.jpg',
  //   publicURL + '/images/watermarked/315cc7627de784a56d49952539aab9e8.jpg',
  //   publicURL + '/images/watermarked/9a91c5292d3ac4c1a58b49017ebcc8b0.jpg',
  //   publicURL + '/images/watermarked/f7d064fb2cf8436b700e7bdb668dca3d.jpg',
  //   publicURL + '/images/watermarked/24991dbf759aa3cef2a120eb98076a9d.jpg',
  //   publicURL + '/images/watermarked/3a18f85431c1c5c5bc707812dcc35994.jpg',
  //   publicURL + '/images/watermarked/9d3122545887d9416dd925be5c3fd7fa.jpg',
  //   publicURL + '/images/watermarked/f94ef8864b7870b5f61a56e5ce96fd96.jpg',
  //   publicURL + '/images/watermarked/2ffd390af4f5dd387f924e3c97f2b7db.jpg',
  //   publicURL + '/images/watermarked/458ba8f3609ba7a613d721b76da6b106.jpg',
  //   publicURL + '/images/watermarked/f2e18f22514dcb9105f2a7330a6562f3.jpg',
  //   publicURL + '/images/watermarked/fba40014d20d8e3cd7459b2c0370fa9c.jpg'
  // ],
  // // klasik modern
  // klasikModern: publicURL + '/images/watermarked/00cefe4c0b855bdb37383fa41a9c7506.jpg',
  // klasikModernList: [
  //   publicURL + '/images/watermarked/00cefe4c0b855bdb37383fa41a9c7506.jpg',
  //   publicURL + '/images/watermarked/2a1bdf21934550c3a44b6196c39129bf.jpg',
  //   publicURL + '/images/watermarked/73cc55a748d0c5a47195b833594b2e19.jpg',
  //   publicURL + '/images/watermarked/b18117d346d6c160445c571efa635519.jpg',
  //   publicURL + '/images/watermarked/16d2234a466c8e3b15f766e930df66a2.jpg',
  //   publicURL + '/images/watermarked/6a6e5f64bf8aa70b87432965241f8817.jpg',
  //   publicURL + '/images/watermarked/75fc37bd20446682f7c8dac79810e3a4.jpg',
  //   publicURL + '/images/watermarked/1fb2f740358ba906c3d1bd4b27350534.jpg',
  //   publicURL + '/images/watermarked/6c1bf6d76bb88e08f5616b5e3ad5b031.jpg',
  //   publicURL + '/images/watermarked/9bf488f9bc14867d18e879f4ceacec2c.jpg'
  // ],
  // // material board
  // materialBoard: publicURL + '/images/watermarked/0304a0523bc1b64d0dcd17ae742f1a6d.jpg',
  // materialBoardList: [
  //   publicURL + '/images/watermarked/0304a0523bc1b64d0dcd17ae742f1a6d.jpg',
  //   publicURL + '/images/watermarked/0ea10e1b69cc62a743340dae6fbbeb15.jpg',
  //   publicURL + '/images/watermarked/2503ccba881a0799a707c13749feea4a.jpg',
  //   publicURL + '/images/watermarked/27c01fd6d7b88fbf4e48d55b2c7a51fc.jpg',
  //   publicURL + '/images/watermarked/339bf2376a1d2dea83608b15ad2f1e29.jpg',
  //   publicURL + '/images/watermarked/3d12b5a4fc5a72e11d7f960b660b36fa.jpg',
  //   publicURL + '/images/watermarked/5bd2e70d4b8799b85954b28b483d859b.jpg',
  //   publicURL + '/images/watermarked/5cbb0710190888db67abb7d7bf532b03.jpg',
  //   publicURL + '/images/watermarked/6a0dd3fb9fb5623f18c7af0a71a02dc3.jpg',
  //   publicURL + '/images/watermarked/71b33bca46efd6cff4843e6c3798ef5f.jpg',
  //   publicURL + '/images/watermarked/92941870334724358a2e269ebfc38558.jpg',
  //   publicURL + '/images/watermarked/92ff6cf22682fadd9b36bf64705b5d01.jpg',
  //   publicURL + '/images/watermarked/930451aab489d164bab004e193ce5aae.jpg',
  //   publicURL + '/images/watermarked/9cc48e65da41b7e6e8a638def3c1e394.jpg',
  //   publicURL + '/images/watermarked/9cf888bdef08bc27650b02429a4b9bd9.jpg',
  //   publicURL + '/images/watermarked/9e3bdfad7546e587f19778eb556707fb.jpg',
  //   publicURL + '/images/watermarked/a222ab2c54dbbbe003188248201e4cfe.jpg',
  //   publicURL + '/images/watermarked/b3ecfa8f397186458054d107e99ce80e.jpg',
  //   publicURL + '/images/watermarked/c31f7e04fc02fae56f3daf80fd78a79d.jpg',
  //   publicURL + '/images/watermarked/c9f61c4cb2c2cfb8ab1f6921fa82d613.jpg',
  //   publicURL + '/images/watermarked/d89cf21778473f89e853141adaaf86e9.jpg',
  //   publicURL + '/images/watermarked/e25ec5daceb45356add4a23c50e9c38a.jpg',
  //   publicURL + '/images/watermarked/e68d5131fdac56759c8e9444c24ca28e.jpg',
  //   publicURL + '/images/watermarked/ea70ae1fba6f173f28f11ccdd68113b3.jpg',
  //   publicURL + '/images/watermarked/f6113120d8ebe4eda7b635da4abbfe65.jpg'
  // ],
  // // modern elegan
  // modernElegan: publicURL + '/images/watermarked/009aba50ee3aef0114ee2b67b1dac837.jpg',
  // modernEleganList: [
  //   publicURL + '/images/watermarked/009aba50ee3aef0114ee2b67b1dac837.jpg',
  //   publicURL + '/images/watermarked/102beea7e8834ca704a3db6f8caa0a1e.jpg',
  //   publicURL + '/images/watermarked/899697505aec3383a147154c4f864927.jpg',
  //   publicURL + '/images/watermarked/9a7ad85e44c74def028d6c56545ada8a.jpg',
  //   publicURL + '/images/watermarked/ec00209ded3d6499bb68ceede3f7b784.jpg'
  // ],
  // // modern industrial
  // modernIndustrial: publicURL + '/images/watermarked/055d9c5056baa924f2119863aa0a2e64.jpg',
  // modernIndustrialList: [
  //   publicURL + '/images/watermarked/055d9c5056baa924f2119863aa0a2e64.jpg',
  //   publicURL + '/images/watermarked/070be4cfed831881193e47a2c4063b4d.jpg',
  //   publicURL + '/images/watermarked/1b746285e9d2e6772775061e99b14b53.jpg',
  //   publicURL + '/images/watermarked/381e9376c5d4a67157efb32c57e1adc1.jpg',
  //   // publicURL + '/images/watermarked/3c1188d0c0e9423e3bad7b7268a75931.jpg',
  //   publicURL + '/images/watermarked/3c7749705cbba0cd081689c3897309cb.jpg',
  //   publicURL + '/images/watermarked/499ee748b4ae139ffa6639c9c789f32e.jpg',
  //   publicURL + '/images/watermarked/82e9262c8ac29af7990afcaf8769fdfa.jpg',
  //   publicURL + '/images/watermarked/8305ec6b0cf18dff0a7ee2a731d52ef8.jpg',
  //   publicURL + '/images/watermarked/8f2bcd93533e33362b2fe1a151b6e898.jpg',
  //   publicURL + '/images/watermarked/d2a101ca87cad9a7cdc613df1c16e56f.jpg',
  //   publicURL + '/images/watermarked/f852c66de955f4320bd419adade604a4.jpg'
  // ],
  // // modern japandi
  // modernJapandi: publicURL + '/images/watermarked/1482de359d619bcba70f73983ed9775a.jpg',
  // modernJapandiList: [
  //   publicURL + '/images/watermarked/1482de359d619bcba70f73983ed9775a.jpg',
  //   publicURL + '/images/watermarked/161886c45805b213af3348141fbf4077.jpg',
  //   publicURL + '/images/watermarked/1982baf8769670f1f8f029ef1fcbe4cc.jpg',
  //   publicURL + '/images/watermarked/2b2dea4d9c03e0c45381ff176f33bcc3.jpg',
  //   publicURL + '/images/watermarked/3625ba12f1f72028c116ce258a029a07.jpg',
  //   publicURL + '/images/watermarked/5c8c9b657a1222a8270aa33d6628b1bd.jpg',
  //   publicURL + '/images/watermarked/5ca5946a5570cc9fec6365b2045c43ce66.jpg',
  //   publicURL + '/images/watermarked/70a6dd631228932fc65f2be3dd062d6b.jpg',
  //   publicURL + '/images/watermarked/a4fa85d96d648523486f6a61af11c0ee.jpg',
  //   publicURL + '/images/watermarked/b5771d3912ea3e26b97ff5bdc58173da.jpg',
  //   publicURL + '/images/watermarked/c0b9f3f26c46c8bf9c41745fd50a2dc6.jpg',
  //   publicURL + '/images/watermarked/ca9dcf7c1c906d4ca2c5c242f687c9d0.jpg',
  //   publicURL + '/images/watermarked/dc705fa2e1d9e810d6f6ea9e8fc05b52.jpg',
  //   publicURL + '/images/watermarked/e6a2477dd72bedbb782a480a08dbf098.jpg',
  //   publicURL + '/images/watermarked/f19de142747afa6b7b366ffe867958c8.jpg'
  // ],
  // // modern kontemporer
  // modernKontemporer: publicURL + '/images/watermarked/012022b3dd07f33f12c428b58e064432.jpg',
  // modernKontemporerList: [
  //   publicURL + '/images/watermarked/012022b3dd07f33f12c428b58e064432.jpg',
  //   publicURL + '/images/watermarked/07450602abab553f8e12624af954f086.jpg',
  //   publicURL + '/images/watermarked/11ee77718dfce6337f4a69f7a89c7000.jpg',
  //   publicURL + '/images/watermarked/1c9fcf483d7df136cc6af9bf00480241.jpg',
  //   publicURL + '/images/watermarked/2e5d24e7756eacd7c00cbb6551fb8b88.jpg',
  //   publicURL + '/images/watermarked/85b5000092b521f53da8ae6927b97c84.jpg',
  //   publicURL + '/images/watermarked/9e2b10f0c0c4058be0f3e195f0b9718b.jpg',
  //   publicURL + '/images/watermarked/ade6fb75446333d73bd3c3d97ab7fe78.jpg',
  //   publicURL + '/images/watermarked/c5c3a8a7268f99896bb144d05f837ce1.jpg',
  //   publicURL + '/images/watermarked/d70605e287f3963317fa2a9753a9b5f6.jpg',
  //   publicURL + '/images/watermarked/deb76e103d04cc6e474cbb36406e23ef.jpg',
  //   publicURL + '/images/watermarked/df289c831ee21a5fd5171ee319895d0f.jpg',
  //   publicURL + '/images/watermarked/e9a02f1a5729b582b9cd5fd1eaa0ba56.jpg',
  //   publicURL + '/images/watermarked/ebf8d4cf4a009dbfd8e2f97b5912898e.jpg',
  //   publicURL + '/images/watermarked/f5aaf539b0fa3151ea0bef7b35da8b73.jpg'
  // ],
  // // modern luxury
  // modernLuxury: publicURL + '/images/watermarked/066ad110cea71523698a88fdef171bc5.jpg',
  // modernLuxuryList: [
  //   publicURL + '/images/watermarked/066ad110cea71523698a88fdef171bc5.jpg',
  //   publicURL + '/images/watermarked/0b574544c6bcc378f0e4cf55909c5887.jpg',
  //   publicURL + '/images/watermarked/0db5f5f819ba404537d5b9283083da81.jpg',
  //   publicURL + '/images/watermarked/2c838dfe50313103f55e7bf56656e199.jpg',
  //   publicURL + '/images/watermarked/2fe0e1e47836ccaa5205dddc0a1671b4.jpg',
  //   publicURL + '/images/watermarked/6ddd762d8199b4dd781005ed6c70ec72.jpg',
  //   publicURL + '/images/watermarked/7c04ec441779c28dde02b44a386f41aa.jpg',
  //   publicURL + '/images/watermarked/99213a2abe33c1f5197d806405e74d2f.jpg',
  //   publicURL + '/images/watermarked/b984cdb29dde8942ef3d9bcde2a80cb5.jpg',
  //   publicURL + '/images/watermarked/d22ff75b4a6819a787c52945a2065bcb.jpg',
  //   publicURL + '/images/watermarked/d4fedc309fc1f2832cbd1997f85fcbaa.jpg',
  //   publicURL + '/images/watermarked/db0d44db5a5824dc4a50ddd0caa38aa6.jpg'
  // ],
  // // modern nature
  // modernNature: publicURL + '/images/watermarked/02ed84e28bb3729e93d91994ddc29bfd.jpg',
  // modernNatureList: [
  //   publicURL + '/images/watermarked/02ed84e28bb3729e93d91994ddc29bfd.jpg',
  //   publicURL + '/images/watermarked/57bfe598e6557c8a22cba2df17523dd5.jpg',
  //   publicURL + '/images/watermarked/6a64f71faa97f3b00f9b758388686da7.jpg',
  //   publicURL + '/images/watermarked/6f3c09452609c4bdfda9cad3fbe4ac3f.jpg',
  //   publicURL + '/images/watermarked/88ee12c33dc74a44678767f26793e13a.jpg',
  //   publicURL + '/images/watermarked/b25a2fcfdc2df63d8ec65b8ce3403c07.jpg',
  //   publicURL + '/images/watermarked/cf268a7037ffdd51255cf349d5a45bfd.jpg',
  //   publicURL + '/images/watermarked/d0d604f62308cf6841f00566a39a91a8.jpg'
  // ],
  // // modern oriental
  // modernOriental: publicURL + '/images/watermarked/13ebb6f86f157dc413b18bbf9a9858c2.jpg',
  // modernOrientalList: [
  //   publicURL + '/images/watermarked/13ebb6f86f157dc413b18bbf9a9858c2.jpg',
  //   publicURL + '/images/watermarked/21ab42168c9b655b416355f52277cceb.jpg',
  //   publicURL + '/images/watermarked/50ceb6f1aae030f6c429cae08ba76a22.jpg',
  //   publicURL + '/images/watermarked/948ef4bacf899990ac00d27a9b1d2257.jpg',
  //   publicURL + '/images/watermarked/9f9932b98b618e39cd4330d550ccd8e2.jpg',
  //   publicURL + '/images/watermarked/a37a4303bfd2a45f2684fbf4fe372785.jpg',
  //   publicURL + '/images/watermarked/b526a7ddcc6d7ac045c0d5c70bd02282.jpg',
  //   publicURL + '/images/watermarked/b52ccaa0c77dcd4e7b0078c6b12b3053.jpg',
  //   publicURL + '/images/watermarked/e968f49674c9e4770aa8a035d2bc5a3d.jpg'
  // ],
  // // popart
  // popArt: publicURL + '/images/watermarked/362b9684254065a9f96f787963467c41.jpg',
  // popArtList: [
  //   publicURL + '/images/watermarked/362b9684254065a9f96f787963467c41.jpg',
  //   publicURL + '/images/watermarked/740b5554862ad315a2b2e4341f7f6a6d.jpg',
  //   publicURL + '/images/watermarked/767d3a3c8728005c04baa135468a1acf.jpg',
  //   publicURL + '/images/watermarked/772ba3133945cff55c584c98ecdc07cc.jpg',
  //   publicURL + '/images/watermarked/7a65cbcf3d2da7eab730254f35e40959.jpg',
  //   publicURL + '/images/watermarked/a10bc2455b2bd3538dba3de8b198df4a.jpg',
  //   publicURL + '/images/watermarked/b5fe22a1e0dd2339ce8e04a327f62508.jpg',
  //   publicURL + '/images/watermarked/fe3e2682b4a7888fb89c4f5aa78bb1e1.jpg'
  // ],
  // wabiSabi: publicURL + '/images/watermarked/5d7d35316ea414ef19af58ef04e64215.jpg',
  // wabiSabiList: [
  //   publicURL + '/images/watermarked/5d7d35316ea414ef19af58ef04e64215.jpg',
  //   publicURL + '/images/watermarked/54ccd3446d63afcdbeaeeafb61f3020c.jpg',
  //   publicURL + '/images/watermarked/659ed440425d1c219b2e1cf3ced41eb0.jpg',
  //   publicURL + '/images/watermarked/ae2dd39a29a5b2ac7fdfe414e0b0f71a.jpg',
  //   publicURL + '/images/watermarked/b25acfa2a59f0b29be0341de11a1e83a.jpg',
  //   publicURL + '/images/watermarked/bba92de3f2ec97831c2b1e2a8f130335.jpg',
  //   publicURL + '/images/watermarked/e655fb6f9772256447a3f3065b89e0f2.jpg',
  //   publicURL + '/images/watermarked/f172b4a6ebb1271051791d7492c1ded1.jpg'
  // ],
  // // shape curve
  // shapeCurve: publicURL + '/images/watermarked/4de723b86420bbe95a45eeecc89048e6.jpg',
  // shapeCurveList: [
  //   publicURL + '/images/watermarked/4de723b86420bbe95a45eeecc89048e6.jpg',
  //   publicURL + '/images/watermarked/522941ff569ccebae00a1b001a7e35c2.jpg',
  //   publicURL + '/images/watermarked/5c09ee94c0196f6b0605abfbd42f6beb.jpg',
  //   publicURL + '/images/watermarked/6ce60edd51d07f7013620a0605bfded4.jpg',
  //   publicURL + '/images/watermarked/7d7b1e6524cf6f2b41f79ffcefe08b69.jpg',
  //   publicURL + '/images/watermarked/a04cf33bfec3989b5a3f4316035c5c52.jpg',
  //   publicURL + '/images/watermarked/b086661671663fbc40cc10783b3e5213.jpg',
  //   publicURL + '/images/watermarked/c06f9c39e508ab9f81eebd78af5818e3.jpg',
  //   publicURL + '/images/watermarked/d6ee736573a95ffe1144bf80f2e5fae4.jpg',
  //   publicURL + '/images/watermarked/fad5d3bdf4c9ecafe84403d671e54b06.jpg'
  // ],
  // // shape square
  // shapeSquare: publicURL + '/images/watermarked/62c0fc982af5be1fd2b10cb48f21c0a4.jpg',
  // shapeSquareList: [
  //   publicURL + '/images/watermarked/62c0fc982af5be1fd2b10cb48f21c0a4.jpg',
  //   publicURL + '/images/watermarked/9be8249ae586c256c368e96e7493080d.jpg',
  //   publicURL + '/images/watermarked/b9acd95ea7ede5de404579693c7ca32f.jpg',
  //   publicURL + '/images/watermarked/c72f4e70c5c21ec643b533bab1310816.jpg',
  //   publicURL + '/images/watermarked/dd2e9f1e9286fcd316af516e6afdcb85.jpg',
  //   publicURL + '/images/watermarked/eb58f0dc56bf5b0de21a28ab542141bd.jpg'
  // ],
  // // shape triangle
  // shapeTriangle: publicURL + '/images/watermarked/04b1016c2902a3e562c95f41d6945f80.jpg',
  // shapeTriangleList: [
  //   publicURL + '/images/watermarked/04b1016c2902a3e562c95f41d6945f80.jpg',
  //   publicURL + '/images/watermarked/0f888f0644a51839a0b18a2c8b57bf85.jpg',
  //   publicURL + '/images/watermarked/393cb2c2da7b5a59cc0e0eb5664d34fa.jpg',
  //   publicURL + '/images/watermarked/4577e4161522b481a42099bb196e0fd1.jpg',
  //   publicURL + '/images/watermarked/46b14af6d6360a01c404b6a7c05324e1.jpg',
  //   publicURL + '/images/watermarked/5c09ee94c0196f6b0605abfbd42f6beb.jpg',
  //   publicURL + '/images/watermarked/734850cba7f2e674e9e883b814a352b9.jpg',
  //   publicURL + '/images/watermarked/aec40f15ea769ac1e3ff356e1e981e22.jpg',
  //   publicURL + '/images/watermarked/d13cea004235f159f7ee30d7bd7e4552.jpg'
  // ]

};

export default imageUrls;
